import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button, TextField } from '@mui/material';
import defaultData from './default.json';

export default function NewCharacter() {
	const [NewCharacter, setNewCharacter] = useState();
	const [created, setCreated] = useState();
	if(!created){
		return (
			<Box sx={{ flexGrow: 1 }}>
				<FormControl variant="filled" fullWidth sx={{ m: 1 }}>
                    <TextField 
                        label="Character Name" 
                        style={{
                            width:"100%",
                        }}
                        multiline={true}
                        onChange={(event)=>{
							setNewCharacter(event.target.value);
						}}
                    />
					<Button
						variant="outlined"
						onClick={()=>{
							defaultData.Basic_Info[1].text = NewCharacter;
							var formData = new FormData();
							formData.append('user_id', window.sessionStorage.getItem('user_id'));
							formData.append('cName', NewCharacter);
							formData.append('data', JSON.stringify(defaultData));
					
							const options = {
								method:"POST",
								body: formData
							};
							
							fetch("NewCharacter.php", options ).then(res => res.json()).then((result) => {
								setCreated(true);
							});
						}}
					>
						Create Character
					</Button>
				</FormControl>
			</Box>
		);
	}else{
		return (<Box>Congratulations! {NewCharacter} was successfully created!</Box>);
	}
}