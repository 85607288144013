import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Item } from './Item';
import saveAPI from './../saveAPI'
import TextField from '@mui/material/TextField';

export default function BasicGridInfo (props) {
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));

    const basic_gridify = () => {
        let grids = [];
        for (let i = 0; i < items.Basic_Info.length; i++) {
            grids.push(
            <Grid item xs={items.Basic_Info[i].xs} md={items.Basic_Info[i].md} >
                <Item>
                    <TextField 
                        label={items.Basic_Info[i].label} 
                        defaultValue={items.Basic_Info[i].text}
                        style={{
                            width:"100%",
                        }}
                        multiline={true}
                        onChange={(event)=>{
                            items.Basic_Info[i].text = event.target.value;
                            setItems(items);
                            saveAPI(items);
                        }}
                    />
                </Item>
            </Grid>
            );
        }
        return grids;
    }

	return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                {basic_gridify()}
            </Grid>
        </Box>
	);
}
