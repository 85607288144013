import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import saveAPI from "../saveAPI";
import { Box } from "@mui/system";
import { UserContext } from "../../App";

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export default function Notes(props){
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));

    return (
        <Box sx={{textAlign:"right"}}>
            <TextField
                label={items['notes'][props.number]['name']}
                defaultValue={items['notes'][props.number]['note']}
                style={{
                    width:"100%",
                }}
                multiline={true}
                onChange={function(event: object){
                    items['notes'][props.number]['note'] = event.target.value;
                    saveAPI(items);
                    setItems(items);
                }
            }>
            </TextField>
            <Button 
                variant="outlined"
                onClick={()=>{
                    async function doThis() {
                        let newNotes = items['notes'];
                        if(props.number > -1){
                            newNotes.splice(props.number,1);
                            items['notes'] = newNotes;
                            saveAPI(items);
                            setItems(items);
                        }
                        await delay(500);
                        window.location.hash = "#Notes";
                        window.location.reload();
                };
                    doThis();
                }}
            >Delete Note</Button>
        </Box>
    );
}

export function NewNote(props){
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
    const [newNote, setNewNote] = useState("");

    return (
        <Box sx={{textAlign:"right"}}>
            <TextField
                label={'New Note'}
                value={newNote}
                style={{
                    width:"100%",
                }}
                multiline={false}
                onChange={function(event: object){
                    setNewNote(event.target.value);
                }
            }>
            </TextField>
            <Button 
                variant="outlined"
                onClick={()=>{
                    async function doThis() {
                        let nn = items['notes'];
                        nn.push({"name":newNote,"note":""});
                        items['notes'] = nn;
                        saveAPI(items);
                        setItems(items);
                        await delay(500);
                        window.location.hash = "#Notes";
                        window.location.reload();
                };
                    doThis();
                }}
            >Create New Note</Button>
        </Box>
    );
}
