import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridRowsProp,
	GridColDef
} from '@mui/x-data-grid';
import { Item } from '../Item';
import saveAPI from '../../saveAPI';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';

function createEmptyLocation(uid) {
	return { id: uid, 
		col_name: "New Location",
		col_location: "",
		col_description: ""
	}
}

function createEmptyItem(uid,locat) {
	return { id: uid.toString(),
		col_item:"",
		col_location:locat.toString(),
		col_quantity:"",
		col_weight:"",
		col_volume:"",
		col_description:""
	}
}

export default class Locations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			chosen_location: null,
			description: ""
		};
	}

	componentDidMount() {
		this.setState({
			items: JSON.parse(window.sessionStorage.getItem('cData')),
			isLoaded: true,
		});
	}

	render() {
		const { error, isLoaded, items, chosen_location, description } = this.state;

		const getUid = () => {
			let index = items.Equipment_Locations_Data.length;
			for(let i = 0; i < items.Equipment_Locations_Data.length; i++){
				if(items.Equipment_Locations_Data[i].id != i){
					index = i;
					break;
				}
			}
			return index;
		}

		const getItemUid = () => {
			let index = items.Item_Grid_Data.length;
			for(let i = 0; i < items.Item_Grid_Data.length; i++){
				if(items.Item_Grid_Data[i].id != i){
					index = i;
					break;
				}
			}
			return index;
		}

		const addNewLocation = () => {
			let tempRows = [...items.Equipment_Locations_Data, createEmptyLocation(getUid())];
			let tempItemRows = [...items.Item_Grid_Data, createEmptyItem(getItemUid(), getUid())];

			tempRows.sort(function(a,b){return a.id - b.id});
			tempItemRows.sort(function(a,b){return a.id - b.id});

			items.Equipment_Locations_Data = tempRows;
			items.Item_Grid_Data = tempItemRows;


			this.setState({items: items});
			saveAPI(items);
		}

		const deleteLocation = async () => {
			let tempItem = JSON.parse(JSON.stringify(items));
			let tempLocations = tempItem['Equipment_Locations_Data'];
			let LocationProp = -1;
			for(let i=0; i < tempLocations.length; i++){
				if(chosen_location === tempLocations[i]['id']){
					LocationProp = i;
					break;
				}
			}
			if(LocationProp > 4){
				tempLocations.splice(LocationProp,1);
				tempItem['Equipment_Locations_Data'] = tempLocations;
				saveAPI(tempItem);
				this.setState({items: tempItem});
				this.setState({chosen_location: null});
				return true;
			}else{
				return false;
			}
		}

		function LocationsToolBar() {
			return(
				<GridToolbarContainer>
					<Stack
						sx={{width:'100%', mb:1}}
						direction='row'
						alignItems='flex-start'
						columnGap={1}
					>
						<GridToolbarColumnsButton />
						<GridToolbarFilterButton />
						<Tooltip title={""} placement='top'>
							<Button size='small' onClick={addNewLocation}><AddCircleOutlineIcon sx={{marginLeft:"-2px", marginRight:"6px"}}/>New Location</Button>
						</Tooltip>
						
						<Tooltip title={"Items in deleted locations will reapear when a new location of the same index is created!"} placement='top'>
							<Button size='small' onClick={()=>{
								deleteLocation().then((result) => {
									if(!result){
										alert("These locations can be renamed, but not deleted: \n\u2022 Weapons, \n\u2022 Armour,  \n\u2022 Backpack, \n\u2022 Belt pouch, \n\u2022 Clothes")
									}
								})
							}}><DeleteIcon sx={{marginLeft:"-2px", marginRight:"6px"}}/>Delete Location</Button>
						</Tooltip>
						
						<Tooltip title={""} placement='top'>
							<Button size='small' onClick={()=>{
									window.location.hash = "#EditLocations";
									window.location.reload();
							}}><CachedIcon sx={{marginLeft:"-2px", marginRight:"6px"}}/>Update Menu</Button>
						</Tooltip>
					</Stack>
				</GridToolbarContainer>
			)
		}
		
		const Description = () => {
			return(
				<TextField
					label={"Description"}
					id={"description"}
					InputLabelProps={{ shrink: true }} 
					defaultValue={this.state.description}
					style={{ 
						width: "100%",
					}}
					minRows={30}
					maxRows={30}
					multiline={true}
					onBlur={(event:( object) => void) => {
						this.setState({description: event.target.value});
						items.Equipment_Locations_Data[chosen_location]['col_description'] = event.target.value;
						this.setState({items: items});
						saveAPI(items);
					}}
				/>
			)
		}
		if (error) {
			return <div>Error: {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			return (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Item>
								<DataGrid id = {"Locations"}
									rows={items.Equipment_Locations_Data} 
									columns={items.Equipment_Locations_Headers}
									autoHeight={true} 
									density={"compact"}
									pageSize={16}
									initialState={{
										...items.initialState,
										columns:{
										columnVisibilityModel:{
											col_sheet: false
										}},
										filter: {
											filterModel: {
												items: [{ columnField: 'col_sheet', operatorValue: 'contains', value: this.props.sheet }],
											}
										}
									}}
									components={{
										Toolbar:LocationsToolBar,
									}}
									onRowClick = { (params: GridRowParams) => {
										this.setState({description: params.row['col_description']});
										this.setState({chosen_location: params.id});
									}}
									onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
										try {
											let tempItem = JSON.parse(JSON.stringify(items));
											let tempLocations = tempItem['Equipment_Locations_Data'];
											let locationsProp = -1;
											for(let i=0; i < tempLocations.length; i++){
												if(params.id === tempLocations[i]['id']){
													locationsProp = i;
													break;
												}
											}
								
											items.Equipment_Locations_Data[locationsProp][params.field] = event.target.value;
											this.setState({items: items});
											saveAPI(items);
										} catch (error) {
											console.log(error);
										}
									}}
									/>
							</Item>
						</Grid>
						<Grid item xs={12} md={6}>
							<Item>
								<Description />
							</Item>
						</Grid>
					</Grid>
				</Box>
			);
		}
	}
}