import { Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useState } from "react";
import { Item } from "./Item";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
export default function SmallHealthTable(props){
    const [showSmallHealth, setShowSmallHealth] = useState(false);

    const HumanoidHealthHeader = (
        [
            {
                field:"col_A",
                headerName:"A",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:false,
                width:60,
                sortable:false,
                align:'center',
            },
            {
                field:"col_B",
                headerName:"B",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:false,
                width:60,
                sortable:false,
                align:'center',
            },
            {
                field:"col_region",
                headerName:"Body Region",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:false,
                width:125,
                sortable:false,
                align:'left',
            },
            {
                field:"col_health_letter",
                headerName:"Health Letter",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:false,
                width:125,
                sortable:false,
                align:'center',
            },
            {
                field:"col_tiny",
                headerName:"Tiny",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
            {
                field:"col_little",
                headerName:"Little",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
            {
                field:"col_average",
                headerName:"Average",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
            {
                field:"col_large",
                headerName:"Large",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
            {
                field:"col_enormous",
                headerName:"Enormous",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
        ]
    );
    const HumanoidHealthData = (
        [
            {
                id:0,
                col_A:"1-3",
                col_B:"1-2",
                col_region:"Right Leg",
                col_health_letter:"D",
                col_tiny:"2",
                col_little:"3",
                col_average:"4",
                col_large:"5",
                col_enormous:"6",
            },
            {
                id:1,
                col_A:"4-6",
                col_B:"3-4",
                col_region:"Left Leg",
                col_health_letter:"D",        
                col_tiny:"2",
                col_little:"3",
                col_average:"4",
                col_large:"5",
                col_enormous:"6",
            },
            {
                id:2,
                col_A:"7-9",
                col_B:"5-6",
                col_region:"Lower Body",
                col_health_letter:"D",
                col_tiny:"2",
                col_little:"3",
                col_average:"4",
                col_large:"5",
                col_enormous:"6",
            },
            {
                id:3,
                col_A:"10-14",
                col_B:"7-8",
                col_region:"Upper Body",
                col_health_letter:"F",
                col_tiny:"4",
                col_little:"5",
                col_average:"6",
                col_large:"7",
                col_enormous:"8",
            },
            {
                id:4,
                col_A:"15-16",
                col_B:"9-12",
                col_region:"Right Arm",
                col_health_letter:"C",
                col_tiny:"1",
                col_little:"2",
                col_average:"3",
                col_large:"4",
                col_enormous:"5",
            },
            {
                id:5,
                col_A:"17-18",
                col_B:"13-16",
                col_region:"Left Arm",
                col_health_letter:"C",
                col_tiny:"1",
                col_little:"2",
                col_average:"3",
                col_large:"4",
                col_enormous:"5",
            },
            {
                id:6,
                col_A:"19-20",
                col_B:"17-20",
                col_region:"Head",
                col_health_letter:"D",        
                col_tiny:"2",
                col_little:"3",
                col_average:"4",
                col_large:"5",
                col_enormous:"6",
            },
        ]
    );



    const handleShowSmallHealth = () => {
        setShowSmallHealth(!showSmallHealth);
    }
    
    const ShowHealthToolBar = () => {
		return(
			<Stack
				sx={{
					display:"flex",
					justifyContent:'start',
					width:'100%', 
					mb:1
				}}
				direction='row'
				alignItems='flex-start'
				columnGap={1}
			>
				<Tooltip title={"Show Humanoid Health Table"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={handleShowSmallHealth}><LocalHospitalIcon /></IconButton></Tooltip>
			</Stack>
		);
	}

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <ShowHealthToolBar/>
            </Grid>
            <Grid item xs={12} md={6}>
                {showSmallHealth && <Item>
                    Humanoids
                    <DataGrid
                        id = {"Humanoid"}
                        columns={HumanoidHealthHeader}
                        rows={HumanoidHealthData}
                        autoHeight={true} 
                        density={"compact"}
                        disableColumnSelector
                        disableColumnMenu
                        hideFooter
                    />
                </Item>}
            </Grid>
        </Grid>
      );
}