import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Item } from './Item';
import saveAPI, { saveUser } from './../saveAPI'
import { 
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	useGridApiRef,
	useGridApiContext,
	useGridSelector,
	gridPageCountSelector,
	GridFilterPanel,
} from '@mui/x-data-grid';
import { Button, IconButton, Pagination, PaginationItem, Stack, Tooltip } from '@mui/material';
import VitalValues from './VitalValues';
import divisionCalc from '../gm-tools/divisionCalc';
import dice from '../gm-tools/dice';
import CasinoIcon from '@mui/icons-material/Casino';
import NoCasinoIcon from '../../CustomIcons/NoCasino';
import CalculateIcon from '@mui/icons-material/Calculate';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

function createEmptySecondaryAttribute(uid) {
	return { id: uid, 
		col_secondary_attribute: uid,
		col_ma_category: "",
		col_ma_division: "",
		col_heroic_ability: "",
		col_merits: "",
		col_misc: "",
		col_skills: "",
		col_total: ""
	}
}

export default function Attributes(props){
	const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
	const [chosen_secondary_attribute, set_chosen_secondary_attribute] = useState();
	const [rollStats, setRollStats] = useState();

	const loadVisibility = () => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		return settings['VisibleAttributes'];
	}
	const loadSaVisibility = () => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		return settings['VisibleSaAttributes'];
	}

	const [MA_visibility_options, set_MA_visibility_options] = useState(loadVisibility);
	const [SA_visibility_options, set_SA_visibility_options] = useState(loadSaVisibility);
	const apiRef = useGridApiRef();
	const [search, setSearch] = useState(false);

	const handleSearch = () => {
		setSearch(!search);
	}

	const MA_Grid_Headers = (
		[
			{
				field:"col_main_attribute",
				headerName:"Main Attribute",
				editable:true,
				width:110,
				sortable:false,
				hideable:false,
			},
			{
				field:"col_value",
				headerName:"Value",
				editable:true,
				type:'number',
				sortable:false,
			},
			{
				field:"col_division",
				headerName:"Division",
				editable:false,
				sortable:false,
			},
			{
				field:"col_temp_value",
				headerName:"Temp Value",
				editable:true,
				type:'number',
				sortable:false,
			},
			{
				field:"col_temp_division",
				headerName:"Temp Division",
				editable:false,
				sortable:false,
			},
			{
				field:"col_max_value",
				headerName:"Max Value",
				editable:true,
				type:'number',
				sortable:false,
			},
			{
				field:"col_max_division",
				headerName:"Max Division",
				editable:false,
				sortable:false,
			}
		]
	);

	const SA_Grid_Headers = (
		[
			{
				field:"col_secondary_attribute",
				headerName:"MA Category",
				editable:true,
				width:200,
				hideable:false,
			},
			{
				field:"col_ma_category",
				headerName:"MA Category",
				editable:true,
				flex:true,
			},
			{
				field:"col_ma_division",
				headerName:"Ma Division",
				editable:true,
				flex:true,
			},
			{
				field:"col_heroic_ability",
				headerName:"Heroic Ability",
				editable:true,
				flex:true,
			},
			{
				field:"col_merits",
				headerName:"Merits",
				editable:true,
				flex:true,
			},
			{
				field:"col_misc",
				headerName:"Misc.",
				editable:true,
				flex:true,
			},
			{
				field:"col_skills",
				headerName:"Skills",
				editable:true,
				flex:true,
			},
			{
				field:"col_total",
				headerName:"Total",
				editable:true,
				flex:true,
			},
			{
				field:"col_sheet",
				headerName:"Sheets",
				editable:true,
				flex:true,
			}
		]
	)

	const getUid = () => {
		let index = items.SA_Grid_Data.length;
		for(let i = 0; i < items.SA_Grid_Data.length; i++){
			if(items.SA_Grid_Data[i].id !== i){
				index = i;
				break;
			}
		}
		return index;
	}

	const addNewSecondaryAttribute = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempRows = [...tempItem.SA_Grid_Data, createEmptySecondaryAttribute(getUid())];
		tempRows.sort(function(a,b){return a.id - b.id});
		tempItem.SA_Grid_Data = tempRows;
		saveAPI(tempItem);
		setItems(tempItem);
	}

	const deleteSecondaryAttribute = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempSA = tempItem['SA_Grid_Data'];
		let sAProp = -1;
		for(let i=0; i < tempSA.length; i++){
			if(chosen_secondary_attribute === tempSA[i]['id']){
				sAProp = i;
				break;
			}
		}
		tempSA.splice(sAProp,1);
		tempItem['SA_Grid_Data'] = tempSA;
		saveAPI(tempItem);
		setItems(tempItem);
		set_chosen_secondary_attribute(null);
	}

	const divCalc = () => {
		let tempItems = JSON.parse(JSON.stringify(items));
		tempItems.MA_Grid_Data.forEach(element => {
			element['col_division'] = divisionCalc(element['col_value']);
			element['col_temp_division'] = divisionCalc(element['col_temp_value']);
			element['col_max_division'] = divisionCalc(element['col_max_value']);
		});
		saveAPI(tempItems);
		setItems(tempItems);
	}

	const SecondaryAttributeToolBar = () => {
		return(
			<GridToolbarContainer>
				<Stack
					sx={{
						display:"flex",
						justifyContent:'start',
						width:'100%', 
						mb:1
					}}
					direction='row'
					alignItems='flex-start'
					columnGap={1}
				>
					<Tooltip title={"Columns visibility options"} ><GridToolbarColumnsButton 
						startIcon={<ViewColumnIcon 
							sx={{
								width:"24px",
								height:"24px",
								marginLeft:"10px",
							}}
							/>}
							sx={{
								width:"32px",
								height:"32px",
								border:"1px solid rgba(0, 0, 0, 0.23)",
								borderRadius:"100%",
								minWidth:"0",
							}}
				  
					/></Tooltip>
					<Tooltip title={"New Secondary Attribute"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={addNewSecondaryAttribute}><AddCircleOutlineIcon /></IconButton></Tooltip>
					<Tooltip title={"Delete Secondary Attribute"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={deleteSecondaryAttribute}><DeleteIcon /></IconButton></Tooltip>
					<Box sx={{
						marginLeft:"auto",
						display:"flex",
						position:'absolute', 
						right:'4px',
						zIndex:'10',
					}}>
						{search && <GridFilterPanel />}
						<Tooltip title={"Search Secondary Attribute"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px", maxHeight:"32px"}} size='small' onClick={handleSearch}><SearchSharpIcon /></IconButton></Tooltip>
					</Box>
				</Stack>
			</GridToolbarContainer>
		)
	}

	const roll = async(type) => {
		let tempItems = JSON.parse(JSON.stringify(items));
		let val = 0;
		tempItems.MA_Grid_Data.forEach(element => {
			switch (type) {
			case 1:
					val = dice(2,6,1);//2D6+6
				break;
			case 2:
					val = dice(2,6,3);//2D6+6 x 3
				break;
			case 3:
					val = dice(3,0,3); //3D6 x 3
				break;
			default:
				break;
			}
			element['col_value'] = val;
			element['col_division'] = divisionCalc(val);
		});
		saveAPI(tempItems);
		setItems(tempItems);
	}

	const FooterTools = () => {
		return(
			<GridToolbarContainer>
				<Stack
					sx={{
						width:'100%',
						display:"flex",
						flexDirection:"row",
						mb:1
					}}
					columnGap={1}
				>
					<Tooltip title={"Columns visibility options"} ><GridToolbarColumnsButton 
						startIcon={<ViewColumnIcon 
							sx={{
								width:"24px",
								height:"24px",
								marginLeft:"10px",
							}}
							/>}
							sx={{
								width:"32px",
								height:"32px",
								border:"1px solid rgba(0, 0, 0, 0.23)",
								borderRadius:"100%",
								minWidth:"0",
							}}
				  
					/></Tooltip>
					<Box
						sx={{
							marginLeft:"auto"
						}}
					>
						{!rollStats && <Tooltip title="Set Divisions"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", padding:"3px"}} size='small' onClick={divCalc}><CalculateIcon/></IconButton></Tooltip>}
						<Tooltip title={rollStats?"Finish":"Roll Attributes"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", padding:"3px"}} size='small' onClick={()=>{setRollStats(!rollStats)}}>{rollStats?<NoCasinoIcon/>:<CasinoIcon/>}</IconButton></Tooltip>
						{rollStats && <Button size='small' onClick={()=>{roll(1)}}><CasinoIcon sx={{marginRight:"6px"}}/>2D6+6</Button>}
						{rollStats && <Button size='small' onClick={()=>{roll(2)}}><CasinoIcon sx={{marginRight:"6px"}}/>2D6+6x3</Button>}
						{rollStats && <Button size='small' onClick={()=>{roll(3)}}><CasinoIcon sx={{marginRight:"6px"}}/>3D6x3</Button>}
					</Box>
					</Stack>
			</GridToolbarContainer>
		)
	}
	
	const handleVisibilityChange = (e) => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		settings.VisibleAttributes = e;
		set_MA_visibility_options(e);
		window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
		saveUser(settings);
	};
	const handleSaVisibilityChange = (e) => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		settings.VisibleSaAttributes = e;
		set_SA_visibility_options(e);
		window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
		saveUser(settings);
	};
	const CustomPagination = () => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      
        return (
			<Box>
				<Pagination
					color="primary"
					variant="outlined"
					shape="circular"
					showFirstButton
					showLastButton
					hideNextButton
					hidePrevButton
					count={pageCount}
					renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
					onChange={(event, value) => apiRef.current.setPage(value - 1)}
				/>
			</Box>
        );
    }

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={4}>
					<Item sx={{marginBottom:"13px"}}>
						<DataGrid 
							id = {"MainAttributes"}
							apiRef={apiRef}
							onColumnVisibilityModelChange={handleVisibilityChange}
							rows={items.MA_Grid_Data}
							columns={MA_Grid_Headers}
							autoHeight={true}
							density={"compact"}
							localeText={{
								toolbarColumns:""
							}}
							initialState={{
								...items.initialState,
								columns:{
									columnVisibilityModel:MA_visibility_options
								},
							}}
							disableColumnFilter
							hideFooter
							components={{
								Toolbar:FooterTools,
							}}
							onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
								items.MA_Grid_Data[params.id][params.field] = params.props.value;
								saveAPI(items);
							}}
						/>
					</Item>
					<Item>
						<VitalValues />
					</Item>
				</Grid>
				<Grid item xs={12} md={8}>
					<Item>
						<DataGrid 
							id = {"SecondaryAttributes"}
							apiRef={apiRef}
							onColumnVisibilityModelChange={handleSaVisibilityChange}
							rows={items.SA_Grid_Data}
							columns={SA_Grid_Headers}
							autoHeight={true}
							density={"compact"}
							pageSize={12} 
							rowsPerPageOptions={12}
							localeText={{
								toolbarColumns:""
							}}
							components={{
								Toolbar:SecondaryAttributeToolBar,
								Pagination:CustomPagination
							}}
							initialState={{
								...items.initialState,
								columns:{
									columnVisibilityModel:SA_visibility_options
								},
							}}
							onRowClick={(Params: GridRowParams) => {
								set_chosen_secondary_attribute(Params.id);
							}}
							onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
								try {
									let tempItem = JSON.parse(JSON.stringify(items));
									let tempSA = tempItem['SA_Grid_Data'];
									let SAProp = -1;
									for(let i=0; i < tempSA.length; i++){
										if(params.id === tempSA[i]['id']){
											SAProp = i;
											break;
										}
									}

									items.SA_Grid_Data[SAProp][params.field] = event.target.value;
									setItems(items);
									saveAPI(items);
								} catch (error) {
									console.log(error);
								}
							}}
						/>
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}