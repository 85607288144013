import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function DeleteCharacter(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = useState(valueProp);
    const radioGroupRef = useRef(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
      var formData = new FormData();
      formData.append('user_id', JSON.stringify([window.sessionStorage.getItem('user_id')]));
      try {
        const options = {
          method:"POST",
          body: formData
        };
        fetch("getCharacterList.php", options ).then(res => res.json()).then((result) => {
          setOptions(result);
          });
    
            if (!open) {
                setValue(valueProp);
            }
      } catch (error) {
        console.log(error);
      }
    }, [valueProp, open]);
  
    const handleEntering = () => {
      if (radioGroupRef.current != null) {
        radioGroupRef.current.focus();
      }
    };
  
    const handleCancel = () => {
      onClose();
    };
  
    const handleOk = () => {
      onClose(value);
    };
  
    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const CharacterList = () => {
      if(options['error'] !== "404"){
        return (options.map((option,index) => (
          <FormControlLabel
            value={option[0]}
            key={option[0]}
            control={<Radio />}
            label={option[1]}
          />
        ))
      )}else{
        return (<></>)
      }
    }
  
  
    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <DialogTitle>Select Character to Delete</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="Delete Character"
            name="dCharacater"
            value={value}
            onChange={handleChange}
          >
            {<CharacterList />}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }