import Context from "@mui/base/TabsUnstyled/TabsContext";

export default class Character  {
	static contextType = Context;
	constructor(character){
		this.character = [];
	}

	getCharacter(){
		return this.character;
	}

	getJsonCharacter(){
		return JSON.stringify(this.character);
	}

	setCharacter(character){
		window.sessionStorage.setItem('cData',character);
		this.character = character;
	}

	saveCharacter(){
		var formData = new FormData();
		formData.append('body', JSON.stringify(this.character));
	
		fetch("setJson.php", {
			method:'POST',
			head:{
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body:formData,
		});
	}
	
	async loadCharacter(){
		var formData = new FormData();
		formData.append('cid', JSON.stringify(window.sessionStorage.getItem('cid')));
	
		fetch("getJson.php", {
			method:'POST',
			head:{
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body:formData,
		}).then(res => res.json()).then((result) => {
			this.setCharacter(JSON.stringify(result));
		});
	}

	async delete(cid){
		var formData = new FormData();
		formData.append('cid', cid);
	
		fetch("deleteCharacter.php", {
			method:'POST',
			head:{
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body:formData,
		});
	}
}