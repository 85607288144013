import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicInfoGrid from './../content/Grids/BasicInfoGrid';
import Attributes from './../content/Grids/Attributes';
import Skills from '../content/Grids/Skills';
import Abilities from '../content/Grids/Abilities';
import Heroism from '../content/Grids/Heroism';
import EquipmentItems from "../content/Grids/Equipment/Items";
import EditLocations from "../content/Grids/Equipment/EditLocataions";
import Weapons from "../content/Grids/Equipment/Weapons";

import CharacterList from "../character/CharacterList";
import Notes, { NewNote } from "../content/Grids/Notes";
import NewCharacter from "../character/NewCharacter";
import { UserContext } from "../App";
import CriticalDamage from "../content/gm-tools/CriticalDamage";
import Fumbling from "../content/gm-tools/Fumbling";
import Profile from "../login/Profile";
import { Divider, Grid } from "@mui/material";
import { Abilities_Grid_Headers, Spell_Grid_Headers,Ceremony_Grid_Headers, Methods_Grid_Headers,Shapes_Grid_Headers,Auras_Grid_Headers,Cantrips_Grid_Headers,Schools_Grid_Headers,Heroic_Proficiencies_Grid_Headers,Heroic_Abilities_Grid_Headers, Armor_Grid_Headers } from "../content/Grids/headers";
import Description from "../content/Grids/Description";
import SmallHealthTable from "../content/Grids/HealthConversionTables";
import CarryingCapacity from "../content/Grids/Equipment/CaryingCapacity";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function InventoryPanels(value, desId, setDesId, desLock, setDesLock){
	const [items, setItems] = useState([]);

	useEffect(()=>{
		const fetchData = async () => {
			try {
				const json = JSON.parse(window.sessionStorage.getItem('cData'))
				setItems(json.Equipment_Locations_Data);
			} catch (error) {
				console.log("error:",error);
			}
		}
		fetchData();
	}, [])

	const tabs = [];
	tabs.push(
		<TabPanel value={value} index={0}>
			<Weapons />
		</TabPanel>
	);
	tabs.push(
		<TabPanel value={value} index={1}>
			<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={12}>
							<Abilities header={Armor_Grid_Headers} data="Armor_Grid_Data" lab="Armour"/>
						</Grid>
						<Grid item xs={12} md={12}>
							{desId > -1 && <Description data={"Armor_Grid_Data"} />}
						</Grid>
						<Grid item xs={12} md={12}>
							<SmallHealthTable/>
						</Grid>
					</Grid>
				</Box>
			</UserContext.Provider>
		</TabPanel>
	);
	for (let i = 2; i< items.length; i++){
		tabs.push(
			<TabPanel value={value} index={i}>
				<EquipmentItems location={items[i].id} />
			</TabPanel>
		);
	}
	
	tabs.push(<TabPanel value={value} index={items.length}>
		<EditLocations />
	</TabPanel>);
	return(
		tabs
	);
}

function InventoryTabs(){
	const [items, setItems] = useState([]);

	useEffect(()=>{
		const fetchData = async () => {
			try {
				const json = JSON.parse(window.sessionStorage.getItem('cData'));
				setItems(json.Equipment_Locations_Data);
			} catch (error) {
				console.log("error:",error);
			}
		}
		fetchData();
	}, [])

	if(items.length > 0){
		const tabs = [];
		for (let i = 0; i< items.length; i++){
			tabs.push(<Tab label={items[i].col_name} {...a11yProps(i)} />);
		}
		tabs.push(<Tab label={'Edit Locations'} {...a11yProps(items.length)} />)
		return(
			tabs
		);
	}
}

export default function MainTabs(props) {
	const [value, setValue] = useState(0);
	const [value1, setValue1] = useState(0);
	const [value2, setValue2] = useState(0);
	const [value3, setValue3] = useState(0);
	const [value4, setValue4] = useState(0);
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
	const [desId, setDesId] = useState(-1);
	const [desLock, setDesLock] = useState('');

	const handleChange = (event, newValue) => {
		setDesId(-1);
		setValue(newValue);
	};
	const handleChange1 = (event, newValue) => {
		setValue1(newValue);
	};
	const handleChange2 = (event, newValue) => {
		setValue2(newValue);
	};
	const handleChange3 = (event, newValue) => {
		setValue3(newValue);
	};
	const handleChange4 = (event, newValue) => {
		setValue4(newValue);
	};

	switch (props.page) {
		case 'MainSheet':
			return (
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%' }}>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
							<Tab label="Basic Info" {...a11yProps(0)} />
							<Tab label="Attributes" {...a11yProps(1)} />
							<Tab label="Abilities" {...a11yProps(2)} />
							<Tab label="Skills" {...a11yProps(3)} />
							<Tab label="Hero" {...a11yProps(4)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<BasicInfoGrid />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Attributes />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Abilities_Grid_Headers} data="Abilities_Grid_Data" lab="Ability"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Abilities_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={3}>
						<Skills />
					</TabPanel>
					<TabPanel value={value} index={4}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Heroism/>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Heroic_Proficiencies_Grid_Headers} data="Heroic_Proficiencies_Grid_Data" lab="Proficiency"/>
										<Abilities header={Heroic_Abilities_Grid_Headers} data="Heroic_Abilities_Grid_Data" lab="Ability"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={desLock} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>

					</TabPanel>
				</Box>
			);
		case 'Inventory':
			return (
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
							{InventoryTabs()}
						</Tabs>
					</Box>
					<Grid container spacing={1}>
						<Grid item xs={12} md={10}>
							{InventoryPanels(value, desId, setDesId, desLock, setDesLock)}
						</Grid>
						<Grid item xs={12} md={2}>
							<CarryingCapacity/>
						</Grid>
					</Grid>
				</Box>
			);
		case 'AllMagic':
			return (
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
							<Tab label="Spells" {...a11yProps(0)} />
							<Tab label="Ceremonies" {...a11yProps(1)} />
							<Tab label="Cantrips" {...a11yProps(2)} />
							<Tab label="Auras" {...a11yProps(3)} />
							<Tab label="Shapes" {...a11yProps(4)} />
							<Tab label="Methods" {...a11yProps(5)} />
							<Tab label="Schools" {...a11yProps(6)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Spell_Grid_Headers} data="Spell_Grid_Data" lab="Spell"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Spell_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Ceremony_Grid_Headers} data="Ceremoney_Grid_Data" lab="Ceremony"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Ceremoney_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Cantrips_Grid_Headers} data="Cantrips_Grid_Data" lab="Cantrip"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Cantrips_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={3}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Auras_Grid_Headers} data="Auras_Grid_Data" lab="Aura"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Auras_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={4}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Shapes_Grid_Headers} data="Shapes_Grid_Data" lab="Shape"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Shapes_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={5}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Methods_Grid_Headers} data="Methods_Grid_Data" lab="Method"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Methods_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
					<TabPanel value={value} index={6}>
						<UserContext.Provider value = {[desId, setDesId, desLock, setDesLock]}>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Abilities header={Schools_Grid_Headers} data="Schools_Grid_Data" lab="School"/>
									</Grid>
									<Grid item xs={12} md={6}>
										{desId > -1 && <Description data={"Schools_Grid_Data"} />}
									</Grid>
								</Grid>
							</Box>
						</UserContext.Provider>
					</TabPanel>
				</Box>
			);
		case 'Notes':
			let t =[];
			let p = [];
			for(let i = 0; i < items['notes'].length; i++){
				t.push(<Tab label={items['notes'][i]['name']} {...a11yProps(i)} />);
				p.push(
					<TabPanel value={value} index={i}>
						<Notes number={i} />
					</TabPanel>
				);
			}
			
			return (
				<UserContext.Provider value={[value]}>
					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
								{t}
								<Tab label="New Note" {...a11yProps(1)} />
							</Tabs>
						</Box>
						{p}
						<TabPanel value={value} index={items['notes'].length}>
							<NewNote/>
						</TabPanel>
					</Box>
				</UserContext.Provider>
			);
		case 'NewCharacter':
			return (<NewCharacter />);
		case 'CharacterList':
			return (<CharacterList/>);
		case 'CriticalDamage':
			return (<CriticalDamage/>);
		case 'Fumble':
			return (<Fumbling />);
		case 'Profile':
			return (<Profile />);
		case 'SingleSheet':
			let ta =[];
			let pa = [];
			for(let i = 0; i < items['notes'].length; i++){
				ta.push(<Tab label={items['notes'][i]['name']} {...a11yProps(i)} />);
				pa.push(
					<TabPanel value={value} index={i}>
						<Notes number={i} />
					</TabPanel>
				);
			}
			return (
				<Box>
					<Divider />
					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%' }}>
							<Tabs value={value1} onChange={handleChange1} aria-label="basic tabs example">
								<Tab label="Basic Info" {...a11yProps(0)} />
								<Tab label="Attributes" {...a11yProps(1)} />
								<Tab label="Abilities" {...a11yProps(2)} />
								<Tab label="Skills" {...a11yProps(3)} />
								<Tab label="Hero" {...a11yProps(4)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<BasicInfoGrid />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<Attributes />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<Abilities header={Abilities_Grid_Headers} data="Abilities_Grid_Data" lab="Ability"/>
						</TabPanel>
						<TabPanel value={value} index={3}>
							<Skills />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<Heroism/>
						</TabPanel>
					</Box>
					<Divider />
					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example">
								{InventoryTabs()}
							</Tabs>
						</Box>
						{InventoryPanels(value2)}
					</Box>
					<Divider />
					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value3} onChange={handleChange3} aria-label="basic tabs example">
								<Tab label="Spells" {...a11yProps(0)} />
								<Tab label="Ceremonies" {...a11yProps(1)} />
								<Tab label="Cantrips" {...a11yProps(2)} />
								<Tab label="Auras" {...a11yProps(3)} />
								<Tab label="Shapes" {...a11yProps(4)} />
								<Tab label="Methods" {...a11yProps(5)} />
								<Tab label="Schools" {...a11yProps(6)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<Abilities header={Spell_Grid_Headers} data="Spell_Grid_Data" lab="Spell"/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<Abilities header={Ceremony_Grid_Headers} data="Ceremony_Grid_Data" lab="Ceremony"/>
						</TabPanel>
						<TabPanel value={value} index={2}>
							<Abilities header={Cantrips_Grid_Headers} data="Cantrips_Grid_Data" lab="Cantrip"/>
						</TabPanel>
						<TabPanel value={value} index={3}>
							<Abilities header={Auras_Grid_Headers} data="Auras_Grid_Data" lab="Aura"/>
						</TabPanel>
						<TabPanel value={value} index={4}>
							<Abilities header={Shapes_Grid_Headers} data="Shapes_Grid_Data" lab="Shape"/>
						</TabPanel>
						<TabPanel value={value} index={5}>
							<Abilities header={Methods_Grid_Headers} data="Methods_Grid_Data" lab="Method"/>
						</TabPanel>
						<TabPanel value={value} index={6}>
							<Abilities header={Schools_Grid_Headers} data="Schools_Grid_Data" lab="School"/>
						</TabPanel>
					</Box>
					<Divider />
					<UserContext.Provider value={[value4]}>
						<Box sx={{ width: '100%' }}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example">
									{ta}
									<Tab label="New Note" {...a11yProps(1)} />
								</Tabs>
							</Box>
							{pa}
							<TabPanel value={value4} index={items['notes'].length}>
								<NewNote/>
							</TabPanel>
						</Box>
					</UserContext.Provider>
				</Box>
			);
		default:
			break;
	}
}