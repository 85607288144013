import {useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import Face5Icon from '@mui/icons-material/Face';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

export default function CriticalDamage(){
	const [resultDamage, setResultDamage] = useState();
	const [damageCount, setDamageCount] = useState();
	const bleeding = [
		(<Box><Box><strong>Lesser Bleeding:</strong> First aid</Box><Box>The character loses 1 Health each 5 rounds of combat.<br/><strong>Slashing Damage:</strong> The character bleed 1 health each 2 rounds.<br/><strong>Arrows:</strong> The bleeding start when the arrow is removed.</Box></Box>),
		(<Box><Box><strong>Heavy Bleeding:</strong> Nursing</Box><Box>The character loses 1 health every round.</Box></Box>),
		(<Box><Box><strong>Inner Bleeding:</strong> First aid</Box><Box>The character loses 1 health each 5 rounds.</Box></Box>),
		(<Box><Box><strong>Cut Throat:</strong> Surgery</Box><Box>The character loses 2 points of health and 5 points of breath, every round. <br/>The character has an accumulating 5% risk of shock, in the starting phase of every round.</Box></Box>),
	]
	const boneDamage = [
		(<Box><Box><strong>Bone Fracture:</strong> First Aid</Box><Box>It is possible to put the bone back together with first aid. The body region regains 1 health each week. The bone is healed when it reaches 0 health.<br/><strong>NB!</strong> Only magic, capable of healing critical damage, may heal such a wound faster than the natural process.</Box></Box>), //extremety
		(<Box><Box><strong>Open Fracature:</strong> Nursing</Box><Box>A physician can heal this wound with surgery.<br/>The wound regains 1 points of health over 2 weeks.<br/><strong>NB!</strong> Only magic, capable of healing critical damage, may heal such a wound faster than the natural process.</Box></Box>), //extremety
		(<Box><Box><strong>Comminuted fracture:</strong> Surgery</Box><Box>The bone has been destroyed. Only magic capable of healing critical damage, can save the extremity.</Box></Box>), //extremety
		(<Box><Box><strong>Rib Fracture:</strong> First Aid</Box><Box>The character has broken 1D3 ribs. These heals 1 health each week. The bone is healed when it reaches 0 health.<br/><strong>NB!</strong> Only magic, capable of healing critical damage, may heal such a wound faster than the natural process.</Box></Box>), //body
		(<Box><Box><strong>Severe rib fracture:</strong> nursing</Box><Box>The character has broken 1D6 ribs. These heals 1 health each week. The bone is healed when it reaches 0 health.<br/><strong>NB!</strong> Only magic, capable of healing critical damage, may heal such a wound faster than the natural process.</Box></Box>), //body
		(<Box><Box><strong>Comminuted fracture of the ribs:</strong> Surgery</Box><Box>The ribcage has been destroyed and organs have been damaged. Only magic capable of healing critical damage may save the characters life.</Box></Box>), //body
		(<Box><Box><strong>Concussion:</strong> First Aid</Box><Box>The character gets dizzy and nauseous. He is incapacitated until his HP in the head reach 1.</Box></Box>), //head
		(<Box><Box><strong>Skull Fracture:</strong> Surgery</Box><Box>The characters skull is fractured. Only healing magic, applied immediately, may save the character.</Box></Box>), //head
		(<Box><Box><strong>Comminuted fracture of the Skull:</strong> None</Box><Box>The characters skull have caved in and they die immediately. Nothing can be done to save them.</Box></Box>), //head
	]
	const fleshWound = (<Box><Box><strong>Flesh wound:</strong> First aid</Box><Box>This wound heals 1 health over {Math.floor(Math.random() * 7)+2} weeks</Box></Box>);
	const paralysisExtremity = [
		(<Box><Box><strong>Damaged Nerve or Tendon</strong></Box><Box>The bodypart is parapyzed for 1D6 weeks.</Box></Box>),
		(<Box><Box><strong>Severely Damaged Nerve or Tendon</strong></Box><Box>The bodypart is parapyzed for 1D6 months.</Box></Box>),
		(<Box><Box><strong>Severed Nerve or Tendon</strong></Box><Box>The bodypart is parapyzed permanently.</Box></Box>),
	]
	const amputationExtremity = [
		(<Box><Box><strong>Ready for Amputation</strong></Box><Box>The body region has been hurt so bad that it is ready to be amputated.<br/>The character is incapacitated until it has been amputated and for 2 weeks after.</Box>{bleeding[0]}</Box>),
		(<Box><Box><strong>Destroyed Joint</strong></Box><Box>The body region has been hurt so bad that it is ready to be amputated.<br/>The character is incapacitated until it has been amputated and for 4 weeks after.</Box>{bleeding[0]}</Box>),
		(<Box><Box><strong>Amputated</strong></Box><Box>The attack which caused the wound amputated the body region. The character is incapacitated for 4 weeks.</Box>{bleeding[1]}</Box>),
	]
	const painExtremity = [
		(<Box><Box><strong>Lasting Pain</strong></Box><Box><strong>Arms:</strong> The first 1D4 weeks after the damage have healed, the characters DC with all actions using the arm, is reduced by 3.<strong>Legs:</strong> The characters movement is reduced by 1 for 1D4 weeks after the damage have healed.</Box></Box>),
		(<Box><Box><strong>Long Lasting Pain</strong></Box><Box><strong>Arms:</strong> The first 1D4 months after the damage have healed, the characters DC with all actions using the arm, is reduced by 3.<strong>Legs:</strong> The characters movement is reduced by 1 for 1D4 months after the damage have healed.</Box></Box>),
		(<Box><Box><strong>Permanent Pain</strong></Box><Box><strong>Arms:</strong> The characters DC with all actions using the arm, is permanently reduced by 3.<strong>Legs:</strong> The characters movement is permanently reduced by 1.</Box></Box>),
	]



	const slashingExtremeties = [
		bleeding[0], bleeding[1], boneDamage[0], fleshWound
	]
	const slashingBody = [
		bleeding[0], bleeding[1], boneDamage[3], boneDamage[4], fleshWound
	]
	const slashingHead = [
		bleeding[0], bleeding[1], bleeding[3], boneDamage[0], boneDamage[1], fleshWound
	]
	const bluntExtremeties = [
		bleeding[2], boneDamage[0], boneDamage[1], boneDamage[2], fleshWound
	]
	const bluntBody = [
		bleeding[2], boneDamage[3], boneDamage[4], boneDamage[5], fleshWound
	]
	const bluntHead = [
		bleeding[2], boneDamage[6], boneDamage[7], boneDamage[8], fleshWound
	]
	const piercingExtremeties = [
		bleeding[0], bleeding[1], fleshWound
	]
	const piercingBody = [
		bleeding[0], bleeding[1], fleshWound
	]
	const piercingHead = [
		bleeding[0], bleeding[1], fleshWound
	]


	const randomBleedingExtremity = (max = bleeding.length) => {
		return bleeding[Math.floor(Math.random() * max)];
	}

	const randomBoneDamageExtremity = (max = boneDamage.length) => {
		return boneDamage[Math.floor(Math.random() * max)];
	}

	const randomPainExtremity = (max = painExtremity.length) => {
		return painExtremity[Math.floor(Math.random() * max)];
	}

	const randomAmputationExtremity = (max = amputationExtremity.length) => {
		return amputationExtremity[Math.floor(Math.random() * max)];
	}

	const randomParalysisExtremity = (max = paralysisExtremity.length) => {
		return paralysisExtremity[Math.floor(Math.random() * max)];
	}

	const lastingDamage = (type, wound) => {
		switch (type) {
			case bleeding[0]:
				return fleshWound
			case bleeding[1]:
				return fleshWound
			case bleeding[2]:
				return fleshWound
			case bleeding[3]:
				return fleshWound
			case boneDamage[0]:
				return randomPainExtremity();
			case boneDamage[1]:
				return randomPainExtremity();
			case boneDamage[2]:
				return randomAmputationExtremity();
			case boneDamage[3]:
				return randomPainExtremity();
			case boneDamage[4]:
				return randomPainExtremity();
			case boneDamage[5]:
				return randomAmputationExtremity();
			case boneDamage[6]:
				return randomPainExtremity();
			case boneDamage[7]:
				return randomPainExtremity();
			case boneDamage[8]:
				return randomAmputationExtremity();
			case fleshWound:
				switch (wound) {
					case "Slashing":
						return randomAmputationExtremity();

					case "Blunt":
						switch (Math.floor(Math.random()*9)) {
							case 0||1||2:
								return randomPainExtremity();
							case 3||4||5:
								return randomParalysisExtremity();
							case 6||7||8:
								return randomBleedingExtremity();
						
							default:
								return;
						}

					case "Piercing":
						return Math.floor(Math.random()*10)+1 < 6 ? randomParalysisExtremity(2) : amputationExtremity[1];
				
					default:
						break;
				}
				return 
		
			default:
				return <></>;
		}
	}

	const criticalExtremety = (damage,damageType) =>{
		let wound = []
		let dType = []
		switch (damageType) {
			case 'Slashing':
				dType = slashingExtremeties
				break;
			case 'Blunt':
				dType = bluntExtremeties				
				break;
			case 'Piercing':
				dType = piercingExtremeties				
				break;
		
			default:
				break;
		}
		wound.push(dType[Math.floor(Math.random() * dType.length)]);
		if(Math.floor(Math.random() * 101) <= damage){
			wound.push(<br/>);
			wound.push(lastingDamage(wound[0],damageType));
		}
		if(damageCount > 1){
			setResultDamage(wound);
		}else{
			setResultDamage("That is all good and well, but how much damage did you suffer? keep in mind that the damage can exceed the health of the region!")
		}
	}
	const criticalBody = (damage,damageType) =>{
		let wound = []
		let dType = []
		switch (damageType) {
			case 'Slashing':
				dType = slashingBody
				break;
			case 'Blunt':
				dType = bluntBody				
				break;
			case 'Piercing':
				dType = piercingBody				
				break;
		
			default:
				break;
		}
		wound.push(dType[Math.floor(Math.random() * dType.length)]);
		if(Math.floor(Math.random() * 101) <= damage){
			wound.push(<br/>);
			wound.push(lastingDamage(wound[0],damageType));
		}
		if(damageCount > 1){
			setResultDamage(wound);
		}else{
			setResultDamage("That is all good and well, but how much damage did you suffer? keep in mind that the damage can exceed the health of the region!")
		}
	}
	const criticalHead = (damage,damageType) =>{
		let wound = []
		let dType = []
		switch (damageType) {
			case 'Slashing':
				dType = slashingHead
				break;
			case 'Blunt':
				dType = bluntHead
				break;
			case 'Piercing':
				dType = piercingHead				
				break;
		
			default:
				break;
		}
		wound.push(dType[Math.floor(Math.random() * dType.length)]);
		if(Math.floor(Math.random() * 101) <= damage){
			wound.push(<br/>);
			wound.push(lastingDamage(wound[0],damageType));
		}
		if(damageCount > 1){
			setResultDamage(wound);
		}else{
			setResultDamage("That is all good and well, but how much damage did you suffer? keep in mind that the damage can exceed the health of the region!")
		}
	}

	return(
		<Box sx={{
			display:'flex',
			flexDirection:'column',
			margin:'10px'
		}}>
			<Box sx={{
				display:'flex',
				width:'100%',
				padding:'5px'
			}}>
				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalExtremety(damageCount, "Slashing");
				}}><EmojiPeopleIcon/>Critical Slashing Damage: Extremety</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalExtremety(damageCount, "Blunt");
				}}><EmojiPeopleIcon/>Critical Blunt Damage: Extremety</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalExtremety(damageCount, "Piercing");
				}}><EmojiPeopleIcon/>Critical Piercing Damage: Extremety</Button>
			</Box>
			<Box sx={{
				display:'flex',
				width:'100%',
				padding:'5px'
			}}>
				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalBody(damageCount, "Slashing");
				}}><AccessibilityIcon/>Critical Slashing Damage: body</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalBody(damageCount, "Blunt");
				}}><AccessibilityIcon/>Critical Blunt Damage: body</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalBody(damageCount, "Piercing");
				}}><AccessibilityIcon/>Critical Piercing Damage: body</Button>
			</Box>
			<Box sx={{
				display:'flex',
				width:'100%',
				padding:'5px'
			}}>
				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalHead(damageCount, "Slashing");
				}}><Face5Icon/>Critical Slashing Damage: Head</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalHead(damageCount, "Blunt");
				}}><Face5Icon/>Critical Blunt Damage: head</Button>

				<Button 
				variant="outlined" 
				sx={{
					width:'100%'
				}}
				onClick={()=>{
					criticalHead(damageCount, "Piercing");
				}}><Face5Icon/>Critical Piercing Damage: head</Button>
			</Box>
			<TextField
				label={<>How much damage did you suffer? <strong>Keep in mind that the damage can exceed the health of the region!</strong></>}
				sx = {{
					marginTop:"10px",
					padding:'5px'
				}}
				placeholder="Damage Suffered"
				onChange={(e)=>{
					setDamageCount(e.target.value * 10)
				}}/>
			<Box sx={{
				borderRadius:'4px',
				margin:'5px',
				padding:'14px'
			}}
			>{resultDamage}</Box>
		</Box>
	);
}