import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
	DataGrid,
} from '@mui/x-data-grid';
import { Item } from './Item';
import saveAPI from '../saveAPI';
import { Vital_Values_Grid_Headers } from './headers';

export default function VitalValues(props){
	const [items, setItems]=useState(JSON.parse(window.sessionStorage.getItem('cData')));
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Item>
						<DataGrid id = {"Abilities"}
							rows={items.Vital_Values_Grid_Data} 
							columns={Vital_Values_Grid_Headers} 
							autoHeight={true} 
							density={"compact"}
							pageSize={16}
							hideFooter={true}
							disableColumnMenu
							onRowClick = { (params: GridRowParams) => {
								this.setState({description: params.row['col_description']});
								this.setState({chosen_ability: params.id});
							}}
							onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
								items.Vital_Values_Grid_Data[params.id][params.field] = params.props.value;
								setItems(items);
								saveAPI(items);
							}}
							/>
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}