import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
} 
from '@mui/x-data-grid';
import { Item } from './../Item';
import saveAPI from '../../saveAPI';
import { TextField } from '@mui/material';
import { Button, Stack } from '@mui/material';

function createEmptyItem(uid,locat) {
	return { id: uid.toString(),
		col_item:"",
		col_location:locat.toString(),
		col_quantity:"",
		col_weight:"",
		col_volume:"",
		col_description:""
	}
}

export default class EquipmentItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			chosen_item: null,
			description: "",
			EquipmentData:[],
		};
	}

	componentDidMount() {
		this.setState({
			items: JSON.parse(window.sessionStorage.getItem('cData')),
			isLoaded: true,
		});
	}

	render() {
		const { error, isLoaded, items, chosen_item, description, EquipmentData } = this.state;
		
		const getUid = () => {
			let index = items.Item_Grid_Data.length;
			for(let i = 0; i < items.Item_Grid_Data.length; i++){
				if(items.Item_Grid_Data[i].id != i){
					index = i;
					break;
				}
			}
			return index;
		}

		const addNewItem = () => {
			let tempRows = [...items.Item_Grid_Data, createEmptyItem(getUid(),this.props.location)];
			tempRows.sort(function(a,b){return a.id - b.id});
			items.Item_Grid_Data = tempRows;
			this.setState({items: items});
			this.setState({EquipmentData: Equipment(items.Item_Grid_Data, this.props.location)})
			saveAPI(items);
		}

		const deleteItem = () => {
			let tempItem = JSON.parse(JSON.stringify(items));
			let tempEquip = tempItem['Item_Grid_Data'];
			let equipProp = -1;
			for(let i=0; i < tempEquip.length; i++){
				if(chosen_item === tempEquip[i]['id']){
					equipProp = i;
					break;
				}
			}
			try {
				if(EquipmentData.length > 1 && equipProp > -1){
					tempEquip.splice(equipProp,1);
				}else if(EquipmentData.length === 1){
					tempEquip[equipProp].col_item = "";
					tempEquip[equipProp].col_quantity = "";
					tempEquip[equipProp].col_weight = "";
					tempEquip[equipProp].col_volume = "";
					tempEquip[equipProp].col_description = "";
				}					
			} catch (error) {
				console.log(error);
			}

			tempItem['Item_Grid_Data'] = tempEquip;
			saveAPI(tempItem);
			this.setState({items: tempItem});
			this.setState({chosen_item: null});
			this.setState({EquipmentData: Equipment(tempEquip, this.props.location)});
		}

		function ItemToolBar() {
			return(
				<GridToolbarContainer>
					<Stack
						sx={{width:'100%', mb:1}}
						direction='row'
						alignItems='flex-start'
						columnGap={1}
					>
						<GridToolbarColumnsButton />
						<GridToolbarFilterButton />
						<Button size='small' onClick={addNewItem}>New Item</Button>
						<Button size='small' onClick={deleteItem}>Delete Item</Button>
					</Stack>
				</GridToolbarContainer>
			)
		}
		
		const Description = () => {
			return(
				<TextField
					label={"Description"}
					id={"description"}
					InputLabelProps={{ shrink: true }} 
					defaultValue={this.state.description}
					style={{ 
						width: "100%",
					}}
					minRows={3}
					maxRows={20}
					multiline={true}
					onBlur={(event:( object) => void) => {
						this.setState({description: event.target.value});
						items.Item_Grid_Data[chosen_item]['col_description'] = event.target.value;
						this.setState({items: items});
						saveAPI(items);
					}}
				/>
			)
		}

		function Equipment(data, loc){
			let Equipment_Items = [];
			for (let i = 0; i < data.length; i++){
				if(data[i].col_location.includes(loc)){
					Equipment_Items.push(data[i]);
				}
			}
			return Equipment_Items;
		}

		if (error) {
			return <div>Error: {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			if(EquipmentData.length === 0){
				this.setState({EquipmentData: Equipment(items.Item_Grid_Data, this.props.location)});
			}
			return (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<Item>
								<DataGrid id = {"Item"}
									rows={EquipmentData} 
									columns={items.Item_Grid_Headers} 
									autoHeight={true} 
									density={"compact"}
									pageSize={16}
									initialState={{
										...items.initialState,
										columns:{
										columnVisibilityModel:{
											col_sheet: false
										}},
										filter: {
											filterModel: {
												items: [{ columnField: 'col_sheet', operatorValue: 'contains', value: this.props.sheet }],
											}
										}
									}}
									components={{
										Toolbar:ItemToolBar,
									}}
									onRowClick = { (params: GridRowParams) => {
										this.setState({description: params.row['col_description']});
										this.setState({chosen_item: params.id});
									}}
									onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
										try {
											let tempItem = JSON.parse(JSON.stringify(items));
											let tempEquip = tempItem['Item_Grid_Data'];
											let equipProp = -1;
											for(let i=0; i < tempEquip.length; i++){
												if(params.id === tempEquip[i]['id']){
													equipProp = i;
													break;
												}
											}

											items.Item_Grid_Data[equipProp][params.field] = event.target.value;
											this.setState({items: items});
											saveAPI(items);
										} catch (error) {
											console.log(error);
										}
									}}
									/>
							</Item>
						</Grid>
						<Grid item xs={12} md={6}>
							<Item>
								<Description />
							</Item>
						</Grid>
					</Grid>
				</Box>
			);
		}
	}
}