import React from "react";
import MainDrawer from "./menu/MainDrawer.js";
import Character from "./content/Character";
import Login from "./login/Login.js";
import { useState } from "react";
import { Box } from "@mui/system";
import ResetPassword from "./login/ResetPassword.js";

export var character = new Character();
export var cName = "";
export const UserContext = React.createContext(null);

function App() {
  const [user, setUser] = useState(false);
  const [cName, setCName] = useState(window.sessionStorage.getItem('cName'));
  character.loadCharacter();
  const [mainChar, setMainChar] = useState(window.sessionStorage.getItem('cData'));
  const [sheetLayout, setSheetLayout] = useState("");
  
  return (
    <Box>
      <UserContext.Provider value={[setUser, setSheetLayout]}>
        {!user && !window.sessionStorage.getItem("user_id") && window.location.search !== "?restore" ? <Login />:""}
      </UserContext.Provider>
      <UserContext.Provider value={setUser}>
        {window.location.search === "?restore" ? <ResetPassword />:""}
      </UserContext.Provider>
      <UserContext.Provider value={[cName, setCName, sheetLayout, setSheetLayout, setMainChar, user, setUser]}>
        {((user || window.sessionStorage.getItem("user_id")) && !(window.location.search === "?restore"))?<MainDrawer char={mainChar}/>:""}
      </UserContext.Provider>
    </Box>
  );
}

export default App;
