export const Abilities_Grid_Headers=(
    [
        {
            field:"col_number",
            headerName:"#",
            editable:true,
            width:75
        },{
            field:"col_type",
            headerName:"Type",
            editable:true,
            width:150
        },{
            field:"col_abilities",
            headerName:"abilities",
            editable:true,
            flex:true
        },{
            field:"col_sheet",
            headerName:"Sheets",
            editable:true,
            flex:true
        }
    ]
);
export const Spell_Grid_Headers=(
    [
        {
            field:"col_spell_name",
            headerName:"Spell",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        },{
            field:"col_divition",
            headerName:"Divition",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        },{
            field:"col_dc_mod",
            headerName:"DC Mod",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        },{
            field:"col_passive",
            headerName:"Passive",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        },{
            field:"col_memorized",
            headerName:"Memorized",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Ceremony_Grid_Headers = (
    [
        {
            field:"col_ceremony_name",
            headerName:"Ceremonies",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        },
        {
            field:"col_divition",
            headerName:"Divition",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Methods_Grid_Headers = (
    [
        {
            field:"col_methods_name",
            headerName:"Methods",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Shapes_Grid_Headers = (
    [
        {
            field:"col_shapes_name",
            headerName:"Shapes",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Auras_Grid_Headers = (
    [
        {
            field:"col_aura_name",
            headerName:"Auras",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Cantrips_Grid_Headers = (
    [
        {
            field:"col_cantrips_name",
            headerName:"Cantrips",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Schools_Grid_Headers = (
    [
        {
            field:"col_school_name",
            headerName:"Schools",
            cellClassName:"super-app-theme--cell",
            editable:true,"flex":true
        },{
            field:"col_divition",
            headerName:"Divition",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true
        }
    ]
);
export const Heroic_Abilities_Grid_Headers = (
    [
        {
            field:"col_heroic_ability",
            headerName:"Heroic Ability",
            flex:true,
            editable:true
        },{
            field:"col_level",
            headerName:"Level",
            width:70,
            editable:true
        },{
            field:"col_bonus",
            headerName:"Bonus",
            width:70,
            editable:true
        },{
            field:"col_total",
            headerName:"Total",
            width:70,
            editable:true
        },{
            field:"col_sheet",
            headerName:"Sheets",
            width:100,
            editable:true
        }
    ]
);
export const Heroic_Proficiencies_Grid_Headers = (
    [
        {
            field:"col_heroic_proficiency",
            headerName:"Heroic Proficiency",
            editable:true,
            flex:true
        },{
            field:"col_sheet",
            headerName:"Sheets",
            editable:true,
            flex:true
        }
    ]
);
export const skill_headers = (
    [
        {
            field: "col_skill",
            headerName: "Skill",
            editable: true,
            width: 200
        },
        {
            field: "col_ma",
            headerName: "MA",
            editable: true,
            width: 75
        },
        {
            field: "col_SL",
            headerName: "SL",
            editable: true,
            width: 75
        },
        {
            field: "col_exp",
            headerName: "EXP",
            editable: true,
            width: 75
        },
        {
            field: "col_notes",
            headerName: "Notes",
            editable: true,
            flex: true
        },
        {
            field: "col_sheet",
            headerName: "settings",
            editable: true,
            flex: true
        },
    ]
);
export const Armor_Grid_Headers = (
    [
        {
            field:"col_body_region",
            headerName:"Body Region",
            cellClassName:"super-app-theme--cell",
            editable:true,
            width:"150"
        },{
            field:"col_hit_A",
            headerName:"A",
            cellClassName:"super-app-theme--cell",
            editable:true,
            width:"60"
        },{
            field:"col_hit_B",
            headerName:"B",
            cellClassName:"super-app-theme--cell",
            editable:true,
            width:"60"
        },{
            field:"col_max_health",
            headerName:"M Health",
            cellClassName:"super-app-theme--cell",
            editable:true,
            width:"100"
        },{
            field:"col_current_health",
            headerName:"C Health",
            cellClassName:"super-app-theme--cell",
            editable:true,
            width:"100"
        },{
            field:"col_armor",
            headerName:"Armor",
            editable:true,
            width:"200"
        },{
            field:"col_bv",
            headerName:"Durability / BV",
            editable:true,
            width:"120"
        },{
            field:"col_weight",
            headerName:"Weight",
            editable:true,
            width:"100"
        },{
            field:"col_volume",
            headerName:"Volume",
            editable:true,
            width:"100"
        },{
            field:"col_abs",
            headerName:"ABS",
            editable:true,
            width:"100"
        },{
            field:"col_shield_abs",
            headerName:"Shield ABS",
            editable:true,
            width:"100"
        },{
            field:"col_total",
            headerName:"Total",
            editable:true,
            width:"100"
        }
    ]
);
export const Weapon_Grid_Headers = (
    [
        {
            field: "col_name1",
            headerName: "name",
            cellClassName:"super-app-theme--cell",
            editable: false,
            flex: false,
            sortable:false,
            width:80,
        },
        {
            field: "col_data1",
            headerName: "data",
            editable: true,
            flex: false,
            sortable:false,
            width:100,
        },
        {
            field: "col_name2",
            headerName: "name",
            cellClassName:"super-app-theme--cell",
            editable: false,
            flex: false,
            sortable:false,
            width:120,
        },
        {
            field: "col_data2",
            headerName: "data",
            editable: true,
            flex: true,
            sortable:false,
        }
]
)
export const Vital_Values_Grid_Headers=(
    [
        {
            field:"col_name",
            headerName:"",
            cellClassName:"super-app-theme--cell",
            editable:false,
            flex:true,
            sortable:false,
        },{
            field:"col_max",
            headerName:"Max",
            editable:true,
            flex:true,
            sortable:false,
        },{
            field:"col_current",
            headerName:"current",
            cellClassName:"super-app-theme--cell",
            editable:true,
            flex:true,
            sortable:false,
        },{
            field:"col_lost",
            headerName:"Lost",
            editable:true,
            flex:true,
            sortable:false,
        }
    ]
);