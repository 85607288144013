import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { character, UserContext } from '../App';
import BasicInfoGrid from '../content/Grids/BasicInfoGrid';
import { green } from '@mui/material/colors';
import styled from '@emotion/styled';

const StyledMenuItem = styled(MenuItem)`
  color: white;
  background-color:green !important;
  :hover {
	background-color:darkgreen !important;
    color: white;
  }
`;

export default function CharacterList() {
	const [error, setError] = React.useState(null);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [items, setItems] = React.useState([]);
	const [cha, setCharacter] = useState(character);
	const [cName, setCName, sheetLayout, setSheetLayout, setMainChar, user, setUser] = useContext(UserContext);

	useEffect(()=>{
		var formData = new FormData();
		formData.append('user_id', JSON.stringify([window.sessionStorage.getItem('user_id')]));
		try {
			const options = {
				method:"POST",
				body: formData
			};
			fetch("getCharacterList.php", options ).then(res => res.json()).then((result) => {
				setItems(result);
				setIsLoaded(true);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			});
		} catch (error) {
			console.log(error);
		}
	},[]);

	const handleChange = event => {
		setCName (items[event.target.value][1]); //character id
		setCharacter(event.target.value); //index
		window.sessionStorage.setItem("cid", items[event.target.value][0]); //character id
		character.loadCharacter(cName);
		setMainChar(character.loadCharacter(cName));
		window.sessionStorage.setItem("cName", items[event.target.value][1]);
	};

	if (error) {
		return <div>Error: {error.message}</div>;
	} else if (!isLoaded) {
		return <div>Loading...</div>;
	} else {
		return (
			<Box sx={{ flexGrow: 1 }}>
				<FormControl variant="filled" fullWidth sx={{ m: 1 }}>
					<InputLabel id="demo-simple-select-label">Select Character</InputLabel>
					<Select
						label="Select Character"
						value={cha}
						labelId="Select Character"
						onChange={handleChange}
					>
						{items['error'] !== "404"?(items.map((data, index) => {if(data[0]===0){return(<StyledMenuItem value={index} >{data[1]}</StyledMenuItem>)}else{return(<MenuItem value={index} >{data[1]}</MenuItem>)}})):<></>}
					</Select>
				</FormControl>
				{/*cName ? <BasicInfoGrid/> : <></>*/}
			</Box>
		);
	}
}