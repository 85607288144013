export default function dice (dice,bonus,times) {
    let val = 0;
    for(let t = 0; t<times; t++){
        let tmp = 0;
        for(let i = 0; i<dice; i++){
            tmp += (Math.floor(Math.random()*6)+1);
        }
        if(val < tmp){val = tmp;} //pick the higher roll
    }
    return val+bonus;
}
