import React, { useState, useEffect, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Article from '@mui/icons-material/Article';
import Inventory2TwoTone from '@mui/icons-material/Inventory2TwoTone';
import MainTabs from "./MainTabs.js";
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import AutoAwesomeTwoTone from '@mui/icons-material/AutoAwesomeTwoTone';
import SportsMartialArtsTwoTone from '@mui/icons-material/SportsMartialArtsTwoTone';
import DeckTwoToneIcon from '@mui/icons-material/DeckTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListAltIcon from '@mui/icons-material/ListAlt';
//import { cName } from '../App.js';
import PropTypes from 'prop-types';
import { a11yProps } from './MainTabs.js';
import { Button, Fab, InputAdornment, Tab, TextField } from '@mui/material';
import { character, UserContext } from '../App.js';
import DeleteCharacter from './DeleteCharacter.js';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import CasinoIcon from '@mui/icons-material/Casino';
import PersonIcon from '@mui/icons-material/Person';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import saveAPI from '../content/saveAPI.js';
import { withStyles } from '@mui/styles';
import { lightBlue, pink } from '@mui/material/colors';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

export default function MainDrawer(props) {
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	//First Layer Buttons
	const [characterSheet, setCharacterSheet] = useState(false);
	const [account, setAccount] = useState(false);
	const [gmTools, setGmTools] = useState(false);	
	//Second Layer Buttons	
	const [MainSheet, setMainSheet] = useState(false); 
	const [Inventory, setInventory] = useState(false); 
	const [AllMagic, setAllMagic] = useState(false); 
	const [Notes, setNotes] = useState(false);
	const [NewCharacter, setNewCharacter] = useState(false);
	const [CharacterList, setCharacterList] = useState(true);
	const [CritDam, setCritDam] = useState(false);
	const [Fumble, setFumble] = useState(false);
	const [Profile, setProfile] = useState(false);
	const [SingleSheet, setSingleSheet] = useState(false);
	//style
	const [chosenElement, setChosenElement] = useState('gray');
	const [
		sheetActiveColor,sheetDarkHoverColor,sheetHoverColor,sheetInactiveColor,
		accountActiveColor,accountDarkHoverColor,accountHoverColor,accountInactiveColor,
		toolsActiveColor,toolsDarkHoverColor,toolsHoverColor,toolsInactiveColor
	] = [
		"rgb(0, 0, 255)","rgba(0, 0, 255,0.75)","rgba(173, 215, 230, 0.7)","rgb(173, 215, 230)",
		"rgb(128, 128, 128)","rgba(128, 128, 128,0.75)","rgba(211, 211, 211, 0.7)","rgb(211, 211, 211)",
		"rgb(0, 128, 0)","rgba(0, 128, 0,0.75)","rgba(144, 238, 144, 0.7)","rgb(144, 238, 144)",
	];
	const [chosenButton, setChosenButton] = useState([-1,-1]);
	//menu points
	const items = ['Main Sheet', 'Inventory', 'All Magic', 'Notes'];
	const mainItems = ['User Profile','New Character', 'Character List', 'Logout', 'Delete Character'];
	const gmItems = ['Critical Damage','Fumbling']

	//
	const [current, setCurrent] = useState("");
	const [cName, setCName, sheetLayout, setSheetLayout, setMainChar, user, setUser] = useContext(UserContext);
	const [openDelete, setOpenDelete] = useState(false);
	const [valueDelete, setValueDelete] = useState("");
	const [openEXP, setOpenEXP] = useState(false);
	const [EXP, setEXP] = useState(window.sessionStorage.getItem('EXP'));
	const [HeroPoints, setHeroPoints] = useState(window.sessionStorage.getItem('HeroPoints'));

	const handlePageChange = async(page) => {
		setSingleSheet(show => page === "SingleSheet" ? !SingleSheet:false);
		setMainSheet(show => page === "MainSheet" ? !MainSheet:false);
		setInventory(show => page === "Inventory" ? !Inventory:false);
		setAllMagic(show => page === "AllMagic" ? !AllMagic:false);
		setNotes(show => page === "Notes" ? !Notes:false);

		setNewCharacter(show => page === "NewCharacter" ? !NewCharacter:false);
		setCharacterList(show => page === "CharacterList" ? !CharacterList:false);
		setProfile(show => page === "Profile" ? !Profile:false);
		if(page === "Profile"){ setCharacterSheet(false); }
		if(page === "NewCharacter" || page === "CharacterList" || page === "CritDam" || page === "Fumble" || page === "Profile"){
			setOpenEXP(false);
		}

		setCritDam(show => page === "CritDam" ? !CritDam:false);
		setFumble(show => page === "Fumble" ? !Fumble:false);
	}

	switch (window.location.hash) {
		case "#Notes":
			setCharacterSheet(show => true);
			setChosenElement('blue');
			if(SingleSheet){
				handlePageChange('SingleSheet');
			}else{
				handlePageChange("Notes");
				setCurrent(items[3]); //6
			}
			window.location.hash = "";			
			break;
		case "#EditLocations":
			setCharacterSheet(show => true);
			setChosenElement('blue');
			if(SingleSheet){
				handlePageChange('SingleSheet');
			}else{
				handlePageChange('Inventory');
				setCurrent(items[1]);
			}
			window.location.hash = "";
			break;
		default:
			break;
	}

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleClickDeleteCharacter = () => {
		setOpenDelete(true);
	}

	const handleCloseDeleteCharacter = (newValue) => {
		setOpenDelete(false);
		if(newValue){
			character.delete(newValue).then(window.location.reload());
		}
	}

	const handleEXPButton = () => {
		let temp = JSON.parse(window.sessionStorage.getItem('cData'));
		setOpenEXP(show => !openEXP);
		setEXP(temp.exp[0].exp);
		setHeroPoints(temp.exp[0].hp);
	}

	const handleEXP = (e) => {
		setEXP(e.target.value);
		let temp = JSON.parse(window.sessionStorage.getItem('cData'));
		temp.exp[0].exp = e.target.value;
		saveAPI(temp);
	}

	const handleHeroPoints = (e) => {
		setHeroPoints(e.target.value);
		let temp = JSON.parse(window.sessionStorage.getItem('cData'));
		temp.exp[0].hp = e.target.value;
		saveAPI(temp);
	}

	const handleChosenButton = (cb) => {
		if(chosenButton[1] === cb[1] && chosenButton[0] === cb[0]){cb=[-1,-1]}
		setChosenButton(cb);
	}
	const StyledButton = withStyles({
		root: {
			backgroundColor: 'transparent',
			display:'flex',
			justifyContent:'start',
			cursor:'pointer',
			width:'100%',
			color:'black',
			'&:hover > nav': {
				backgroundColor: 'rgba(0,0,0,0.1)',
				transition:'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				borderRadius:'100%',
	},}})(Button);
	
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar
				sx={{
					backgroundColor:chosenElement
				}}
				>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap component="div" sx={{display:"flex", width:"100%"}}>
						<Box sx={{height:"56px", maxHeight:"56px", minHeight:"56px", lineHeight:"56px"}}>{cName} {(cName && current)?"|":""} {current} 
						</Box>
						{(chosenElement === "blue") && <Fab sx={{marginLeft:"auto"}} onClick={handleEXPButton}>
							<LocalLibraryIcon />
						</Fab>}
						{((chosenElement === "blue") && openEXP) && <Box>
							<TextField
								sx={{ 
									input: { color: 'white' },
									border:"1px solid white", 
									marginLeft:"5px",
									borderRadius:"4px",
									maxWidth:"175px",
								}}
								InputProps={{
									startAdornment:(
										<InputAdornment position="start" sx={{ color: 'white'}}>
											<Box sx={{ color:'white' }}>Bonus EXP:</Box>
										</InputAdornment>
									)
								}}
								onChange={handleEXP}
								value={EXP}
							/><TextField
								sx={{ 
									input: { color: 'white' }, 
									border:"1px solid white",
									marginLeft:"5px",
									borderRadius:"4px",
									maxWidth:"175px",
								}}
								InputProps={{
									startAdornment:(
										<InputAdornment position="start" sx={{ color: 'white'}}>
											<Box sx={{ color:'white' }}>Hero Points:</Box>
										</InputAdornment>
									)
								}}
								onChange={handleHeroPoints}
								value={HeroPoints}
							/>
						</Box>}
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<StyledButton onClick={handleDrawerClose}>
						<Box>{window.sessionStorage.user_name}</Box>
						<nav style={{
							marginLeft: 'auto',
							height: '39px',
							width: '39px',
							maxWidth: '39px',
							maxHeight: '39px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							<MenuIcon/>
						</nav>
					</StyledButton>
				</DrawerHeader>
				{cName && <List>
					<ListItemButton
						key={'Character Sheet'}
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
							backgroundColor:(characterSheet || SingleSheet)? sheetActiveColor:sheetInactiveColor,
							color: (characterSheet || SingleSheet)? "white":"black",
							"&.Mui-focusVisible": {
								backgroundColor: (characterSheet || SingleSheet)? sheetActiveColor:sheetInactiveColor
							},
							":hover": {
								backgroundColor: (characterSheet || SingleSheet)? sheetDarkHoverColor:sheetHoverColor
							}
						}}
						onClick={()=>{
							setChosenElement('blue');
							const tmp = JSON.parse(window.sessionStorage.getItem('user_settings'));
					
							if(sheetLayout === "MultiSheet" || tmp['SheetLayout'] === "MultiSheet" ){
								handleChosenButton([0,0]);
								setCharacterSheet(show=>!characterSheet);
								handlePageChange("MainSheet");
							}else{
								handleChosenButton([-1,-1]);
								handlePageChange("SingleSheet");
							}
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
								color:'inherit'
							}}
						>
						
							{[<Article color='inherit'/>]}
						</ListItemIcon>
						<ListItemText primary={cName} sx={{ opacity: open ? 1 : 0 }} />
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}>
					</ListItemIcon>
				</ListItemButton>
				</List>}
				<Divider />
				{characterSheet && <List>
					{items.map((text, index) => (
						<ListItemButton
							key={text}
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
								backgroundColor: (chosenButton[1] === index && chosenButton[0] === 0)? sheetActiveColor:sheetInactiveColor,
								color: (chosenButton[1] === index && chosenButton[0] === 0)? "white":"black",
								"&.Mui-focusVisible": {
									backgroundColor: (characterSheet && chosenButton[1] === index)? sheetActiveColor:sheetInactiveColor
								},
								":hover": {
									backgroundColor: (characterSheet && chosenButton[1] === index)? sheetDarkHoverColor:sheetHoverColor
								}
	
							}}
							onClick={() =>{
								setChosenElement('blue');
								if(cName){
												switch (text) {
													case "Main Sheet":
														handlePageChange("MainSheet");
														setCurrent(items[0]);
														handleChosenButton([0,index]);
														break;
													case "Inventory":
														handlePageChange("Inventory");
														setCurrent(items[1]);
														handleChosenButton([0,index]);
														break;
													case "All Magic":
														handlePageChange("AllMagic");
														setCurrent(items[2]);//3
														handleChosenButton([0,index]);
														break;
													case "Notes":
														handlePageChange("Notes");
														setCurrent(items[3]);//6
														handleChosenButton([0,index]);
														break;
												
													default:
														break;
												}	
								}
							}}>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
									color:'inherit'
								}}>
								{/*index % 7 === 0 ? <Article /> : [<Inventory2TwoTone />, <SportsMartialArtsTwoTone/>, <AutoAwesomeTwoTone/>, <HikingTwoToneIcon/>, <DeckTwoToneIcon/>, <DescriptionTwoToneIcon/>][index-1]*/}
								{index % 7 === 0 ? <Article color='inherit'/> : [<Inventory2TwoTone color='inherit'/>, <AutoAwesomeTwoTone color='inherit'/>, <DescriptionTwoToneIcon color='inherit'/>][index-1]}
							</ListItemIcon>
							<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					))}
				</List>}
				<Divider />
				<List>
					<ListItemButton
						key={'Account'}
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
							backgroundColor: account? accountActiveColor:accountInactiveColor,
							color:account? "white":"black",
							"&.Mui-focusVisible": {
								backgroundColor: account? accountActiveColor:accountInactiveColor
							},
							":hover": {
								backgroundColor: account? accountDarkHoverColor:accountHoverColor
							}
						}}
						onClick={()=>{
							setAccount(show=>!account);
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
								color:'inherit'
							}}
						>
						
							{[<PersonIcon color='inherit'/>]}
						</ListItemIcon>
						<ListItemText primary={'Account'} sx={{ opacity: open ? 1 : 0 }} />
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}>
					</ListItemIcon>
				</ListItemButton>
				</List>
				<Divider />
				{account && <List>
					{mainItems.map((text, index) => (
						<ListItemButton
							key={text}
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
								backgroundColor: (chosenButton[1] === index && chosenButton[0] === 1)? accountActiveColor:accountInactiveColor,
								color: (chosenButton[1] === index && chosenButton[0] === 1)? "white":"black",
								"&.Mui-focusVisible": {
									backgroundColor: (account || chosenButton[1] === index)? accountActiveColor:accountInactiveColor
								},
								":hover": {
									backgroundColor: (account || chosenButton[1] === index)? accountDarkHoverColor:accountHoverColor
								}
							}}
							onClick = {() => {
								setChosenElement('gray');
								switch (index) {
									case 0:
										handlePageChange("Profile");
										setCurrent(mainItems[0]);
										handleChosenButton([1,index]);
 										break;
									case 1:
										handlePageChange("NewCharacter");
										setCurrent(mainItems[1]);
										handleChosenButton([1,index]);
										break;
									case 2:
										handlePageChange("CharacterList");
										setCurrent(mainItems[2]);
										handleChosenButton([1,index]);
										break;
									case 3:
										handleChosenButton([1,-1]);
										setCName('');
										setMainChar('');
										setUser('');
										window.sessionStorage.clear();
										window.location.reload();
										break;
									case 4:
										handleClickDeleteCharacter();
										break;
									default:
										break;
								}
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
									color:'inherit'
								}}
							>
								{[<AccountBoxIcon color='inherit'/>,<Article color='inherit'/>, <ListAltIcon color='inherit'/>, <LogoutIcon color='inherit'/>, <DeleteForeverIcon color='inherit'/>][index]}
							</ListItemIcon>
							<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					))}
				</List>}
				<Divider />
				<List>
					<ListItemButton
						key={'GM-Tools'}
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
							backgroundColor: gmTools? toolsActiveColor:toolsInactiveColor,
							color:gmTools? "white":"black",
							"&.Mui-focusVisible": {
								backgroundColor: gmTools? toolsActiveColor:toolsInactiveColor
							},
							":hover": {
								backgroundColor: gmTools? toolsDarkHoverColor:toolsHoverColor
							}
						}}
						onClick={()=>{
							setGmTools(show=>!gmTools);
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
								color:'inherit',
							}}
						>
						
							{[<CasinoIcon />]}
						</ListItemIcon>
						<ListItemText primary={'GM-Tools'} sx={{ opacity: open ? 1 : 0 }} />
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}>
					</ListItemIcon>
				</ListItemButton>
				</List>
				<Divider />
				{gmTools && <List>
					{gmItems.map((text,index)=>(
						<ListItemButton
						key={text}
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
							backgroundColor: (chosenButton[1] === index && chosenButton[0] === 2)? toolsActiveColor:toolsInactiveColor,
							color: (chosenButton[1] === index && chosenButton[0] === 2)? "white":"black",
							"&.Mui-focusVisible": {
								backgroundColor: (gmTools && chosenButton[1] === index)? toolsActiveColor:toolsInactiveColor
							},
							":hover": {
								backgroundColor: (gmTools && chosenButton[1] === index)? toolsDarkHoverColor:toolsHoverColor
							}
					}}
						onClick = {() => {
							setChosenElement('green');
							switch (index) {
								case 0:
									handlePageChange("CritDam");
									setCurrent(gmItems[0]);
									handleChosenButton([2,index]);
									break;
								case 1:
									handlePageChange("Fumble");
									setCurrent(gmItems[1]);
									handleChosenButton([2,index]);
									break;
								default:
									break;
							}
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
								color:'inherit'
							}}
						>
							{[<HeartBrokenIcon />,<CrisisAlertIcon/>][index]}
						</ListItemIcon>
						<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
					</ListItemButton>
					))}
				</List>}
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				{MainSheet && <Box><MainTabs page={'MainSheet'} data={props.char} /></Box>}
				{Inventory && <Box><MainTabs page={'Inventory'} /></Box>}
				{AllMagic && <Box><MainTabs page={'AllMagic'} /></Box>}
				{Notes && <Box><MainTabs page={'Notes'} /></Box>}
				{NewCharacter && <Box><MainTabs page={'NewCharacter'} /></Box>}
				{CritDam && <Box><MainTabs page={'CriticalDamage'} /></Box>}
				{Fumble && <Box><MainTabs page={'Fumble'} /></Box>}
				{Profile && <Box><MainTabs page={'Profile'} /></Box>}
				{SingleSheet && <Box><MainTabs page={'SingleSheet'} /></Box>}
				{CharacterList && <Box><MainTabs page={'CharacterList'} /></Box>}
				<DeleteCharacter
					id='DeleteCharacterDialog'
					keepMounted
					open={openDelete}
					onClose={handleCloseDeleteCharacter}
					value={valueDelete}
				/>
			</Box>
		</Box>
	);
}
