import {useState} from 'react';
import {Box, Button } from '@mui/material';
import MeleeIcon from '@mui/icons-material/SportsKabaddi';
import RangedIcon from '@mui/icons-material/SocialDistance';
import PracticeMeleeIcon from '@mui/icons-material/SportsMartialArts';
import MiscastIcon from '@mui/icons-material/AutoFixHigh';
import TameMiscastIcon from '@mui/icons-material/Flare';

export default function Fumbling(){
	const [resultFumble, setResultFumble] = useState();
    const [descFumble, setDescFumble] = useState();
	const basicFumbling = [
        (<Box><Box>The character stumbles and lose 3AP.</Box></Box>),
        (<Box><Box>The character drop part of his armor, if he has any. Roll D20 on table B to see which piece is lost.</Box></Box>),
        (<Box><Box>The character falls and must spend 6AP getting back up.</Box></Box>),
        (<Box><Box>The character takes a bad step and sprain the foot. Movement are reduced by 1 for the next week.</Box></Box>),
        (<Box><Box>The character creates an opening for all enemies in mele combat range.</Box></Box>),
        (<Box><Box>Hit closest friend.</Box></Box>)
	]
    const weaponFumbling = [
        (<Box><Box>The character drops the weapon in the same area</Box></Box>),
        (<Box><Box>The character drop the weapon and it’s knocked 1D3 areas in a random direction</Box></Box>),
        (<Box><Box>The weapon loose 1D4BV</Box></Box>),
        (<Box><Box>The weapon is destroyed</Box></Box>)
	]
    const practiceFumbling = [
        (<Box><Box>The character hit their adversary’s total health with a perfect strike, causing full damage and ignoring all armour. In addition, all bashing damage is converted into blunt damage.</Box></Box>),
        (<Box><Box>The character hit their own total health with a perfect strike, causing full damage and ignoring all armour. In addition, all bashing damage is converted into blunt damage.</Box></Box>)
    ]
    const criticalDamageMiscast = [
        (<Box><Box>The character cannot gain MP naturally, for as many days as the spells might.</Box></Box>),
        (<Box><Box>The character cannot gain MP at all, for as many days as the spells might. Any attempt to transfer MP to the character, cause 1D3 points of damage to his MP.</Box></Box>),
        (<Box><Box>The character loses the ability to spend MP for as many days as the spells might.</Box></Box>),
        (<Box><Box>The character loose 1D4 points of MP, permanently.</Box></Box>),
        (<Box><Box>The character loses as much MP as the spells power level, permanently.</Box></Box>),
        (<Box><Box>The character permanently loses the ability to regain MP.</Box></Box>),
        (<Box><Box>The character completely forgets the spell he was casting and must learn it again.</Box></Box>),
        (<Box><Box>The character is struck by intense and overwhelming terror for D8 days. After this the character gain a random phobia.</Box></Box>),
        (<Box><Box>The character has a chance of going into shock equivalent to the might of his spell multiplied by the power level it was cast with.</Box></Box>),

    ]
    const criticalMedium = [
        (<Box><Box>The medium mispronounce a word in their prayer, asking the diety for something beyond the scope of their covenant. <br/>Which spell is cast then, is determined by the GM, but whichever spell is cast, is cast with that many power levels.</Box></Box>),
        (<Box><Box>The medium mispronounce a word in their prayer, asking the diety for something beyond the scope of their covenant. <br/>Instead of casting the spell intended, the spell caster cast the covenant spell with that many power levels.</Box></Box>),
        (<Box><Box>The medium mispronounce a word in their prayer, asking the diety for something beyond the scope of their covenant. <br/>The spell cast consumes 1 point of permanently sacrificed MP and whatever the character asked for, which is determined by the GM, is cast with an irrisistable force.</Box></Box>),
        (<Box><Box>The medium insult their patron, who punish them by inflicting [Power Level D6] points of *damage upon them. <br/> *the damage type is determined by the GM.</Box></Box>),
        (<Box><Box>The medium insult their patron, who promptly dispurse of their covenant</Box></Box>),
        (<Box><Box>The medium mortally insult their patron who promptly dispurse of their covenant and turn their wrath towards their former medium. The character recieves a bouty, relative to the might of the patron.</Box></Box>),
        (<Box><Box>The medium mispronounce the name of their patron and is heard by a benevolent spirit of chaos.<br/>The mediums prayer is answered, {(Math.floor(Math.random() * 10)-5) < 1 && "but the character is corrupted and gain " + (Math.floor(Math.random() * 2)+1) + "points of corruption"}</Box></Box>),
        (<Box><Box>The medium mispronounce the name of their patron and is heard by a malevolent spirit of chaos.<br/>The mediums suffers [Power level D6] points of chaos damage {(Math.floor(Math.random() * 10)-5) < 1 && ", and the character is corrupted and gain " + (Math.floor(Math.random() * 2)+1) + "points of corruption"}</Box></Box>),
    ]

    //description
    const miscastDesc = (<Box><p>
        <strong>Miscast</strong><br/>
        The character’s magic does something they were not supposed to do. If using channeling, the character may have spoken a word wrong, if using medium the character may have insulted his patron.
        Regardless of what happened, the character can either try to continue whatever he is doing, or he may try to contain the damage.
        </p>
        <p>
            <strong>Contain the damage</strong><br/>
            To contain the damage the character must try to resist the spells might with his own willpower, after the resistance table.
            If the character succeeds, he loses as much MP as the spells power level.
            A failed roll means the character sustains critical damage to his mind. See the table for critical damage after miscasting.
        </p>
        <p>
            <strong>Taming the miscast</strong><br/>
            (Use this method only if the GM approve!)<br/>
            The character tries to salvage the spell and cast it anyway. He must spend an additional 1D10AP and increase its power level by 1D6, while spending MP accordingly. The spells DC is reduced by 1D10+5 and it’s might is increased by as much.
            The spell, which is cast, does something entirely different and much less controlled than what the character intended. It is up to the GM and the player to device this new magical effect, which must still be in relation to the original spell.
            A failed roll means the character sustains critical damage to his mind. See the table for critical damage after miscast.
        </p><strong>Your roll on the critical damage after miscasting table:</strong><br/></Box>)

//

    const meleeFumbling = [
        [(0),(0.45*15/20*100),basicFumbling[0]], 
        [((0.45*15/20*100)),(0.50*15/20*100),basicFumbling[1]], 
        [((0.50*15/20*100)),(0.65*15/20*100),basicFumbling[2]], 
        [((0.65*15/20*100)),(0.75*15/20*100),basicFumbling[3]], 
        [((0.75*15/20*100)),(0.90*15/20*100),basicFumbling[4]], 
        [((0.90*15/20*100)),(0.95*15/20*100),basicFumbling[5]], 
        [((0.95*15/20*100)),((0.95*15/20*100)+15),weaponFumbling[0]], 
        [(((0.95*15/20*100)+15)),((0.95*15/20*100)+20),weaponFumbling[1]], 
        [(((0.95*15/20*100)+20)),((0.95*15/20*100)+25),weaponFumbling[2]], 
    ]
    const rangedFumbling = [
        [(0),(0.45*15/20*100),basicFumbling[0]], 
        [((0.45*15/20*100)),(0.50*15/20*100),basicFumbling[1]], 
        [((0.50*15/20*100)),(0.65*15/20*100),basicFumbling[2]], 
        [((0.65*15/20*100)),(0.75*15/20*100),basicFumbling[3]], 
        [((0.75*15/20*100)),(0.90*15/20*100),basicFumbling[4]], 
        [((0.90*15/20*100)),(0.95*15/20*100),basicFumbling[5]], 
        [((0.95*15/20*100)),((0.95*15/20*100)+15),weaponFumbling[0]], 
        [(((0.95*15/20*100)+15)),((0.95*15/20*100)+20),weaponFumbling[1]], 
        [(((0.95*15/20*100)+20)),((0.95*15/20*100)+25),weaponFumbling[3]], 
    ]
    const meleePracticeFumbling = [
        [(0),(0.45*15/20*100),basicFumbling[0]], 
        [((0.45*15/20*100)),(0.50*15/20*100),basicFumbling[1]], 
        [((0.50*15/20*100)),(0.65*15/20*100),basicFumbling[2]], 
        [((0.65*15/20*100)),(0.75*15/20*100),basicFumbling[3]], 
        [((0.75*15/20*100)),(0.90*15/20*100),basicFumbling[4]], 
        [((0.90*15/20*100)),(0.95*15/20*100),basicFumbling[5]], 
        [((0.95*15/20*100)),((0.95*15/20*100)+5),weaponFumbling[0]], 
        [(((0.95*15/20*100)+15)),((0.95*15/20*100)+10),weaponFumbling[1]], 
        [(((0.95*15/20*100)+20)),((0.95*15/20*100)+15),weaponFumbling[3]], 
        [(((0.95*15/20*100)+15)),((0.95*15/20*100)+20),practiceFumbling[0]], 
        [(((0.95*15/20*100)+20)),((0.95*15/20*100)+25),practiceFumbling[1]], 
    ]
    const Miscast = [
        [(0),(10),criticalDamageMiscast[0]],
        [(10),(20),criticalDamageMiscast[1]],
        [(20),(30),criticalDamageMiscast[2]],
        [(30),(40),criticalDamageMiscast[3]],
        [(40),(60),criticalDamageMiscast[4]],
        [(60),(70),criticalDamageMiscast[5]],
        [(70),(80),criticalDamageMiscast[6]],
        [(80),(90),criticalDamageMiscast[7]],
        [(90),(100),criticalDamageMiscast[8]],
    ]
    const MiscastMedium = [
        [(0),(12.5),[criticalMedium[0]]],
        [(12.5),(25),[criticalMedium[1]]],
        [(25),(37.5),[criticalMedium[2]]],
        [(37.5),(50),[criticalMedium[3]]],
        [(50),(62.5),[criticalMedium[4]]],
        [(62.5),(75),[criticalMedium[5]]],
        [(75),(87.5),[criticalMedium[6]]],
        [(87.5),(101),[criticalMedium[7]]],
    ]

	const randomFumble = (type) => {
        let r = Math.random() * 101;
        let t = [];
        switch (type) {
            case 'melee':
                t = meleeFumbling;
                break;
            case 'ranged':
                t = rangedFumbling;
                break;
            case 'practice':
                t = meleePracticeFumbling;
                break;
            case 'channeling':
                t = Miscast;
                break;
            case 'medium':
                t = MiscastMedium;
                break;
            default:
                break;
        }

        for (let i = 0; i < t.length-1; i++){
            let t1 = t[i][0];
            let t2 = t[i][1];
            let test = r > t1 && r < t2;
            if(test){
                return [t[i][2]];
            }
        }
        return [[(<>You must roll twice on this table!<br/><br/><strong>Your first roll: </strong></>)], randomFumble(type), [(<><br/><strong>Your second roll: </strong></>)], randomFumble(type)];
	}

	return(
		<Box sx={{
			display:'flex',
			flexDirection:'column',
			margin:'10px'
		}}>
			<Box sx={{
				display:'flex',
				width:'100%',
				padding:'5px'
			}}>
				<Button 
                    variant="outlined" 
                    sx={{
                        width:'100%'
                    }}
                    onClick={()=>{
                        setResultFumble(randomFumble('melee'));
                        setDescFumble("")
				    }}><MeleeIcon sx={{marginRight:'8px'}}/>Fumble: Melee Weapon</Button>
				<Button 
                    variant="outlined" 
                    sx={{
                        width:'100%'
                    }}
                    onClick={()=>{
                        setResultFumble(randomFumble('ranged'));
                        setDescFumble("")
				    }}><RangedIcon sx={{marginRight:'8px'}}/>Fumble: Ranged Weapon</Button>
				<Button 
                    variant="outlined" 
                    sx={{
                        width:'100%'
                    }}
                    onClick={()=>{
                        setResultFumble(randomFumble('practice'));
                        setDescFumble("")
				    }}><PracticeMeleeIcon sx={{marginRight:'8px'}}/>Fumble: Melee Practice Weapon</Button>
				<Button 
                    variant="outlined" 
                    sx={{
                        width:'100%'
                    }}
                    onClick={()=>{
                        setResultFumble(randomFumble('channeling'));
                        setDescFumble(miscastDesc);
				    }}><MiscastIcon sx={{marginRight:'8px'}}/>Miscast: Channeling</Button>
				<Button 
                    variant="outlined" 
                    sx={{
                        width:'100%'
                    }}
                    onClick={()=>{
                        setResultFumble(randomFumble('medium'));
                        setDescFumble("")
				    }}><TameMiscastIcon sx={{marginRight:'8px'}}/>Miscast: Medium</Button>
			</Box>
			<Box sx={{
				border:'1px solid blue',
				borderRadius:'4px',
				margin:'5px',
				padding:'14px'
			}}
			>{descFumble}{resultFumble}</Box>
		</Box>
	);
}