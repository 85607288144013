import { useContext, useState } from 'react';
import { Box, TextField, Button, InputAdornment, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UserContext } from '../App';
import { saveUser } from '../content/saveAPI';

export default function Profile() {
    const [username, setUserName] = useState(window.sessionStorage.getItem('user_name'));
    const [email, setEmail] = useState(window.sessionStorage.getItem('user_email'));
    const [createNewPassword, setCreateNewPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passMatch, setPassMatch] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [message, setMessage] = useState("");
    const [cName, setCName, sheetLayout, setSheetLayout] = useContext(UserContext);
    const [settings, setSettings] = useState(JSON.parse(window.sessionStorage.getItem('user_settings')));

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
        setPassMatch (!( (event.target.value.length > 7) && (confirmNewPassword === event.target.value)));
    }
    const handleOldPassword = (event) => {
        setOldPassword(event.target.value);
    }

    const handleConfirmNewPassword = (event) => {
        setConfirmNewPassword(event.target.value);
        setPassMatch (!((event.target.value.length > 7) && (newPassword === event.target.value)));
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const HandleCancel = () => {
        setNewPassword("");
        setOldPassword("");
        setConfirmNewPassword("");
    }
    
    const handleCreateNewPassword = () => {
        var formData = new FormData();
        formData.append('pass', JSON.stringify([oldPassword,newPassword]));
        formData.append('id', JSON.stringify(window.sessionStorage.getItem('user_id')));

        fetch("changeUser.php", {
          method:'POST',
          head:{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body:formData,
        }).then(rows => rows.json()).then((result) => {
            if(result > 0){
                setCreateNewPassword(!createNewPassword);
                setMessage("Your password was changed!");
                HandleCancel();
            }else{
                setMessage("Ups! Something is wrong... Please double check your old password before contacting support!");    
            }
        });
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        var formData = new FormData();
        formData.append('email', JSON.stringify(event.target.value));
        formData.append('id', JSON.stringify(window.sessionStorage.getItem('user_id')));

        fetch("changeUser.php", {
          method:'POST',
          head:{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body:formData,
        })
    }

    const handleNameChange = (event) => {
        setUserName(event.target.value);
        var formData = new FormData();
        formData.append('name', JSON.stringify(event.target.value));
        formData.append('id', JSON.stringify(window.sessionStorage.getItem('user_id')));

        fetch("changeUser.php", {
          method:'POST',
          head:{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body:formData,
        })
    }

    const handleSheetChange = (event) => {
        settings['SheetLayout'] = event.target.checked ? "SingleSheet":"MultiSheet";
        window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
        setSheetLayout(event.target.checked ? "SingleSheet":"MultiSheet");
        setSettings(settings);
        saveUser(settings);
    }

    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",
            padding:"50px",
            maxWidth:"750px",
        }}>
            <FormControlLabel control={<Checkbox onChange={handleSheetChange} checked={(sheetLayout === "SingleSheet" || settings['SheetLayout'] === "SingleSheet") ? true : false} />} label="Character Sheet in single page" />
            <TextField 
                sx={{
                    marginBottom:"15px"
                }}
                value={username}
                variant="outlined"
                label = "Username"
                onChange={handleNameChange}
            />
            <TextField
                sx={{
                    marginBottom:"15px"
                }}
                value={email}
                variant="outlined"
                label = "E-mail"
                onChange={handleEmailChange}
            />
            {createNewPassword && <TextField 
                sx={{
                    marginBottom:"15px"
                }}
                label = "Old Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                InputProps={{
                    endAdornment:(
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    )
                }}
                value={oldPassword}
                onChange = {handleOldPassword}
            />}
            {createNewPassword && <TextField 
                sx={{
                    marginBottom:"15px"
                }}
                label = "Confirm New Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                InputProps={{
                    endAdornment:(
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    )
                }}
                value={newPassword}
                onChange = {handleNewPassword}
            />}
            {createNewPassword && <TextField 
                sx={{
                    marginBottom:"15px"
                }}
                label = "Confirm New Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                InputProps={{
                    endAdornment:(
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    )
                }}
                value={confirmNewPassword}
                onChange = {handleConfirmNewPassword}
            />}
            <Button
                sx={{
                    marginBottom:"15px"
                }}
                variant={createNewPassword ? "outlined":"contained"}
                onClick={()=>{
                    if(createNewPassword){HandleCancel();}
                    setCreateNewPassword(!createNewPassword);
                    setMessage("");
                }}
            >{createNewPassword ? "Cancel":"New Password"}</Button>
            {createNewPassword && <Button
                sx={{
                    marginBottom:"15px"
                }}
                variant="contained"
                onClick={()=>{
                    handleCreateNewPassword();
                }}
                disabled={passMatch}
            >Confirm New Password</Button>}
            <Box>{message}</Box>
        </Box>
    );
}