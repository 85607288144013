import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { 
	DataGrid,
	GridToolbarContainer,
	useGridApiContext,
	useGridSelector,
	gridPageCountSelector,
	GridFilterPanel
} from '@mui/x-data-grid';
import { Item } from './Item';
import saveAPI, { saveUser } from '../saveAPI';
import { IconButton, Pagination, PaginationItem } from '@mui/material';
import { Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddTableRow from '../../CustomIcons/AddTableRow';
import RemoveTableRow from '../../CustomIcons/RemoveTableRow';
import { Tooltip } from '@mui/material';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { UserContext } from '../../App';

function createEmptyAbility(uid, header) {
	let res = {id: uid};
	for(let i = 0; i< header.length; i++){
		res[header[i].field] = "";
	}
	res.col_description = "";

	return res;
}

export default function Abilities(props) {
	const [desId, setDesId, desLock, setDesLock] = useContext(UserContext);
	const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
	const [chosen_ability, setChosen_ability] = useState(null);
	const loadRowSize = () => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		if(settings['AbilitiesRowSize']){
			return settings['AbilitiesRowSize'];
		}
		return settings['AbilitiesRowSize'];
	}

	const [rowSize, setRowSize] = useState(loadRowSize);
	const [search, setSearch] = useState(false);

	const getUid = () => {
		let index = items[props.data].length;
		for(let i = 0; i < items[props.data].length; i++){
			if(items[props.data][i].id !== i){
				index = i;
				break;
			}
		}
		return index;
	}
	const handleSearch = () => {
		setSearch(!search);
	}
	const addNewAbility = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempRows = [...tempItem[props.data], createEmptyAbility(getUid(), props.header)];
		tempRows.sort(function(a,b){return a.id - b.id});
		tempItem[props.data] = tempRows;
		saveAPI(tempItem);
		setItems(tempItem);
	}
	const deleteAbility = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempAbilities = tempItem[props.data];
		let abilityProp = -1;
		for(let i=0; i < tempAbilities.length; i++){
			if(chosen_ability === tempAbilities[i]['id']){
				abilityProp = i;
				break;
			}
		}
		tempAbilities.splice(abilityProp,1);
		tempItem[props.data] = tempAbilities;
		saveAPI(tempItem);
		setItems(tempItem);
		setChosen_ability(null);
	}
	const handleRowSize = (val) => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		let tmpSize = rowSize+val;
		settings.AbilitiesRowSize = tmpSize;
		setRowSize(tmpSize);
		window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
		saveUser(settings);
	}
	const CustomPagination = () => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      
        return (
			<Box
				sx={{
					display:"flex",
					width:"100%",
					justifyContent:'end',
				}}
			>
				<Box>
					<Tooltip title="+1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", padding:"3px"}} size='small' onClick={()=>{handleRowSize(1)}}><AddTableRow/></IconButton></Tooltip>
					<Tooltip title="-1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", marginRight:"3px", padding:"3px"}} size='small' onClick={()=>{handleRowSize(-1)}}><RemoveTableRow/></IconButton></Tooltip>
				</Box>
				<Pagination
					color="primary"
					variant="outlined"
					shape="circular"
					showFirstButton
					showLastButton
					hideNextButton
					hidePrevButton
					count={pageCount}
					renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
					onChange={(event, value) => apiRef.current.setPage(value - 1)}
				/>
			</Box>
        );
    }
	const AbilitiesToolBar = () => {
		return(
			<GridToolbarContainer>
				<Stack
					sx={{
						display:"flex",
						justifyContent:'start',
						width:'100%', 
						mb:1
					}}
					direction='row'
					alignItems='flex-start'
					columnGap={1}
				>
					<Tooltip title={"New " + props.lab}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={addNewAbility}><AddCircleOutlineIcon /></IconButton></Tooltip>
					<Tooltip title={"Delete " + props.lab}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={deleteAbility}><DeleteIcon /></IconButton></Tooltip>
					<Box sx={{
						marginLeft:"auto",
						display:"flex",
						position:'absolute', 
						right:'4px',
						zIndex:'10',
					}}>
						{search && <GridFilterPanel />}
						<Tooltip title={"Search " + props.lab}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px", maxHeight:"32px"}} size='small' onClick={handleSearch}><SearchSharpIcon /></IconButton></Tooltip>
					</Box>
				</Stack>
			</GridToolbarContainer>
		)
	}

	return (
		<Item>
			<DataGrid 
				id = {"Abilities"}
				rows={items[props.data]} 
				columns={props.header} 
				autoHeight={true} 
				density={"compact"}
				pageSize={rowSize}
				rowsPerPageOptions={rowSize}
				disableColumnSelector
				hideFooterSelectedRowCount
				columnVisibilityModel={{
					col_number: false,
					col_sheet: false,
				}}
				initialState={{
					...items.initialState,
				}}
				components={{
					Toolbar:AbilitiesToolBar,
					Pagination:CustomPagination
				}}
				onRowClick = { (params: GridRowParams) => {
					setChosen_ability(params.id);

					try {
						let tempItem = JSON.parse(JSON.stringify(items));
						let tempAbilities = tempItem[props.data];
						for(let i=0; i < tempAbilities.length; i++){
							if(params.id === tempAbilities[i]['id']){
								setDesId(i);
								setDesLock(props.data);
								break;
							}
						}
					} catch (error) {
						console.log(error);
					}
				}}
				onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
					try {
						let tempItem = JSON.parse(JSON.stringify(items));
						let tempAbilities = tempItem[props.data];
						let abilitiesProp = -1;
						for(let i=0; i < tempAbilities.length; i++){
							if(params.id === tempAbilities[i]['id']){
								abilitiesProp = i;
								break;
							}
						}

						items[props.data][abilitiesProp][params.field] = event.target.value;
						setItems(items);
						saveAPI(items);
					} catch (error) {
						console.log(error);
					}
				}}
				/>
		</Item>
	);
}