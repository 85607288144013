import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Item } from "../Item";

export default function CarryingCapacity(props){
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
    const str = items.MA_Grid_Data[0].col_value;
    let willpower = 1;
    for(let i = 0; i < items.SA_Grid_Data.length; i++){
        if(items.SA_Grid_Data[i].col_secondary_attribute === "Willpower"){
            if(items.SA_Grid_Data[i].col_total > 1){
                willpower = items.SA_Grid_Data[i].col_total;
            }
        }
    }
    let PowerLevel = 1;
    for(let i = 0; i < items.SA_Grid_Data.length; i++){
        if(items.SA_Grid_Data[i].col_secondary_attribute === "PowerLevel"){
            if(items.SA_Grid_Data[i].col_total > 1){
                PowerLevel = items.SA_Grid_Data[i].col_total;
            }
        }
    }
    

    const caryingHeader = (
        [
            {
                field:"col_str",
                headerName:"Strength",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:false,
                width:105,
                sortable:false,
                align:'center',
            },
            {
                field:"col_burden",
                headerName:"Burden",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'center',
            },
            {
                field:"col_modifier",
                headerName:"Mod.",
                cellClassName:"super-app-theme--cell",
                editable:false,
                flex:true,
                sortable:false,
                align:'left',
            },
        ]
    );
    const CaryingData = (
        [
            {
                id:0,
                col_str:"STR * 0 - 1,5",
                col_burden:(str*1.5) +"kg",
                col_modifier:"±0",
            },
            {
                id:1,
                col_str:"STR * 1,5 - 3",
                col_burden:(str*3) +"kg",
                col_modifier:"-1",
            },
            {
                id:2,
                col_str:"STR * 3 - 4,5",
                col_burden:(str*3.5) +"kg",
                col_modifier:"-3",
            },
            {
                id:3,
                col_str:"STR * 4,5 - 6",
                col_burden:(str*6) +"kg",
                col_modifier:"-7†",
            },
            {
                id:4,
                col_str:"STR * 6 - 7,5",
                col_burden:(str*7.5) +"kg",
                col_modifier:"-11†",
            },
            {
                id:5,
                col_str:"STR * 7,5 - 9",
                col_burden:(str*9) +"kg",
                col_modifier:"-15†",
            },
        ]
    );
    const BodyBuilderCaryingData = (
        [
            {
                id:0,
                col_str:"STR * 0 - 1,5",
                col_burden:((parseInt(willpower)+parseInt(str))*1.5) +"kg",
                col_modifier:"±0",
            },
            {
                id:1,
                col_str:"STR * 1,5 - 3",
                col_burden:((parseInt(willpower)+parseInt(str))*3) +"kg",
                col_modifier:"-1",
            },
            {
                id:2,
                col_str:"STR * 3 - 4,5",
                col_burden:((parseInt(willpower)+parseInt(str))*3.5) +"kg",
                col_modifier:"-3",
            },
            {
                id:3,
                col_str:"STR * 4,5 - 6",
                col_burden:((parseInt(willpower)+parseInt(str))*6) +"kg",
                col_modifier:"-7†",
            },
            {
                id:4,
                col_str:"STR * 6 - 7,5",
                col_burden:((parseInt(willpower)+parseInt(str))*7.5) +"kg",
                col_modifier:"-11†",
            },
            {
                id:5,
                col_str:"STR * 7,5 - 9",
                col_burden:((parseInt(willpower)+parseInt(str))*9) +"kg",
                col_modifier:"-15†",
            },
        ]
    );
    const TelekinesisCaryingData = (
        [
            {
                id:0,
                col_str:"STR * 0 - 1,5",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*1.5) +"kg",
                col_modifier:"±0",
            },
            {
                id:1,
                col_str:"STR * 1,5 - 3",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*3) +"kg",
                col_modifier:"-1",
            },
            {
                id:2,
                col_str:"STR * 3 - 4,5",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*3.5) +"kg",
                col_modifier:"-3",
            },
            {
                id:3,
                col_str:"STR * 4,5 - 6",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*6) +"kg",
                col_modifier:"-7†",
            },
            {
                id:4,
                col_str:"STR * 6 - 7,5",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*7.5) +"kg",
                col_modifier:"-11†",
            },
            {
                id:5,
                col_str:"STR * 7,5 - 9",
                col_burden:((parseInt(willpower)*parseInt(PowerLevel))*9) +"kg",
                col_modifier:"-15†",
            },
        ]
    );

    return (
        <Box>
            <Item sx={{marginTop:"24px"}}>
                <Tooltip title="Carrying Capacity is calculated using STR."><Box>Carrying Capacity: STR</Box></Tooltip>
                <DataGrid
                    id = {"Humanoid"}
                    columns={caryingHeader}
                    rows={CaryingData}
                    autoHeight={true} 
                    density={"compact"}
                    disableColumnSelector
                    disableColumnMenu
                    hideFooter
                />
            </Item>
            <Item sx={{marginTop:"24px"}}>
                <Tooltip title="Use this box if you have the Body Builder Proficiency. It is calculated using STR + Willpower. The Total column is used for the calculation!)"><Box>Carrying Capacity: STR + Willpower</Box></Tooltip>
                <DataGrid
                    id = {"Humanoid"}
                    columns={caryingHeader}
                    rows={BodyBuilderCaryingData}
                    autoHeight={true} 
                    density={"compact"}
                    disableColumnSelector
                    disableColumnMenu
                    hideFooter
                />
            </Item>
            <Item sx={{marginTop:"24px"}}>
            <Tooltip title="Willpower * PowerLevel - (Add PowerLevel to your Secondary Attributes to calculate this automatically. The Total column is used for the calculation!)"><Box>Carrying Capacity: Telekinesis</Box></Tooltip>
                <DataGrid
                    id = {"Humanoid"}
                    columns={caryingHeader}
                    rows={TelekinesisCaryingData}
                    autoHeight={true} 
                    density={"compact"}
                    disableColumnSelector
                    disableColumnMenu
                    hideFooter
                />
            </Item>
        </Box>
      );
}