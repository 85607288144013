import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import React, { useContext, useState } from "react"
import { UserContext } from "../App";

export default function Login (props) {
  const [setUser, setSheetLayout] = useContext(UserContext);
  let [authMode, setAuthMode] = useState(false)
  const [password, setPassword] = useState();
  const [passrep, setPassRep] = useState();
  const [username, setUserName] = useState();
  const [email, setEmail] = useState();
  const [vertical, setVertical] = useState(window.innerHeight < window.innerWidth ? "10%" : "30%");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const [message, setMessage] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  window.addEventListener('resize', () => {setVertical(window.innerHeight < window.innerWidth ? "10%" : "30%")});
  
  const changeAuthMode = () => {
    if(!authMode){
      setMessage("");
    }
    setAuthMode(!authMode);
  }

  const handleConfirmPassword = (props) => {
    setPassRep(props);
    if(props === password || !authMode){
      setConfirmPassword(false);
    }else{
      setConfirmPassword(true);
    }
  }

  const handlePassword = (props) => {
    setPassword(props);
    if(props === passrep || !authMode){
      setConfirmPassword(false);
    }else{
      setConfirmPassword(true);
    }
  }

  const verifyUser = () => {
    var formData = new FormData();
    formData.append('info', JSON.stringify([password,username,email]));
  
    fetch("login.php", {
      method:'POST',
      head:{
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body:formData,
    }).then(res => res.json()).then((result) => {
      if(!(result === "Success: User Created!")){
        setUser(result);
        window.sessionStorage.setItem("user_id", result['id']); //user id
        window.sessionStorage.setItem("user_email", result['email']); //user email
        window.sessionStorage.setItem("user_name", result['username']); //user name
        window.sessionStorage.setItem("user_settings", result['user_settings']); //user settings
      }else{
        setMessage(result);
      }
    }).then(() => {
      changeAuthMode();
      setEmail(null);
      setPassRep(null);
      if(message === "Success: User Created!" || window.sessionStorage.getItem("user_id")){
        window.location.reload();
      }
    });
  }

  return (
    <Box sx={{
        textAlign:"center",
        marginTop:vertical
    }}>
        <Box>
          <h3>Sign In</h3>
          {message}
          <Box>
            {authMode?"Already registered":"Don't have an accout"}?{" "}
            {<Button variant="text" onClick={changeAuthMode}>{authMode?"Sign In":"Sign Up"}</Button>}
          </Box>
          {authMode?<Box><FormControl sx={{ m: 1, width: '25ch' }} variant="filled"><InputLabel htmlFor="filled-adornment-email">E-mail</InputLabel><FilledInput id="filled-adornment-email" type='text' value={email} onChange={(event)=>{setEmail(event.target.value);}} /></FormControl></Box>:""}
          <Box>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
              <InputLabel htmlFor="filled-adornment-username">Username</InputLabel>
              <FilledInput
                id="filled-adornment-username"
                type='text'
                value={username}
                onChange={(event)=>{setUserName(event.target.value);}}
                onKeyPress= {(e) => {
                  if (e.key === 'Enter' && !authMode) {
                    verifyUser()
                  }
                }}
                />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(event)=>{handlePassword(event.target.value);}}
                onKeyPress= {(e) => {
                  if (e.key === 'Enter' && !authMode) {
                    verifyUser()
                  }
                }}
                />
            </FormControl>
          </Box>
          {authMode?<Box><FormControl sx={{ m: 1, width: '25ch' }} variant="filled"><InputLabel htmlFor="filled-adornment-confirm-password">Confirm Password</InputLabel><FilledInput id="filled-adornment-confirm-password" type={showPassword ? 'text' : 'password'} endAdornment={<InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">{showPassword ? <VisibilityOff /> : <Visibility />} </IconButton></InputAdornment>} value={passrep} onChange={(event)=>{handleConfirmPassword(event.target.value);}}/></FormControl></Box>:""}
          <Box sx={{ margin:"5px" }}>
            <Button 
              variant="contained" 
              disabled={confirmPassword}
              onClick={()=>{verifyUser()}}
            >
              {authMode?"Create Account":"Login"}
            </Button>
          </Box>
          <Box sx={{ margin:"5px" }}>
            Forgot <a href={window.location.origin + "/?restore"}>password?</a>
          </Box>
        </Box>
    </Box>
  )
}