import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";


export default function ResetPassword(){
    const [hash, setHash] = useState(false);
    const [createNewPassword, setCreateNewPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passMatch, setPassMatch] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    
    useEffect(()=>{
        if(window.location.hash){ setHash(true); }
    },[]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
        setPassMatch (!( (event.target.value.length > 7) && (confirmNewPassword === event.target.value)));
    }

    const handleConfirmNewPassword = (event) => {
        setConfirmNewPassword(event.target.value);
        setPassMatch (!((event.target.value.length > 7) && (newPassword === event.target.value)));
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleCreateNewPassword = () => {
        var formData = new FormData();
        formData.append('info', JSON.stringify([newPassword,window.location.hash]));

        fetch("resetPassword.php", {
          method:'POST',
          head:{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body:formData,
        }).then(rows => rows.json()).then((result) => {
            if(result > 0){
                window.location.replace(window.location.origin);
            }else{
                setMessage("Ups! Something is wrong... Please double check your old password before contacting support!");    
            }
        });
    }

    const handleNewPasswordKey = () => {
        var formData = new FormData();
        formData.append('email', JSON.stringify(email));

        fetch("resetPassword.php", {
          method:'POST',
          head:{
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body:formData,
        }).then(rows => rows.json()).then((result) => {
            if(result > 0){
                setCreateNewPassword(!createNewPassword);
                setMessage("An email notificaiton have been sent to your email address. please follow the instruction in that email to reset your password!\nPlease check your trash folder, if you cannot see the message in the inbox!");
                setEmail("");
            }else{
                setMessage("Ups! Something is wrong... Please try again before contacting support!");    
            }
        });
    }
    if(hash){
        return(
            <Box sx={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                padding:"50px",
                maxWidth:"750px",
            }}>
                    <TextField 
                    sx={{
                        marginBottom:"15px"
                    }}
                    label = "Confirm New Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    value={newPassword}
                    onChange = {handleNewPassword}
                />
                <TextField 
                    sx={{
                        marginBottom:"15px"
                    }}
                    label = "Confirm New Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    value={confirmNewPassword}
                    onChange = {handleConfirmNewPassword}
                />

                <Button
                    sx={{
                        marginBottom:"15px"
                    }}
                    variant="contained"
                    onClick={()=>{
                        handleCreateNewPassword();
                    }}
                    disabled={passMatch}
                >Confirm New Password</Button>
                <Box>{message}</Box>
            </Box>
        )
    }else{
        return(
            <Box sx={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
            }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "50px",
                        maxWidth: "750px",
                        width:"100%"
                    }}
                >
                    <TextField
                        sx={{
                            marginBottom:"15px"
                        }}
                        type='email'
                        variant='outlined'
                        value={email}
                        onChange={handleEmailChange}
                        label="Write the E-mail dddress associated with your account!"
                    />
                    {(message !== "An email notificaiton have been sent to your email address. please follow the instruction in that email to reset your password!\nPlease check your trash folder, if you cannot see the message in the inbox!") && <Button
                        variant="contained"
                        sx={{
                            marginBottom:"15px"
                        }}
                        onClick={handleNewPasswordKey}
                    >Reset Password</Button>}
                    <Button
                        variant="outlined"
                        sx={{
                            marginBottom:"15px"
                        }}
                        onClick={()=>{
                            window.location.replace(window.location.origin);
                        }}
                    >Return to Login</Button>
                    <Box>{message}</Box>
                </Box>
            </Box>
        )
    }
}