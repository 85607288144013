import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Item } from './Item';
import saveAPI from '../saveAPI';
import { TextField } from '@mui/material';
import Abilities from './Abilities';
import { Heroic_Abilities_Grid_Headers, Heroic_Proficiencies_Grid_Headers } from './headers';

export default function Heroism (props) {
	const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));

	const heroic_gridify = () => {
		let grids = [];
		for (let i = 0; i < Object.keys(items.Heroic_attributes).length; i++) {
			grids.push(
				<Grid item xs={12} md={4}>
					<Item>
						<TextField 
							label={items.Heroic_attributes[i].label}
							defaultValue={items.Heroic_attributes[i].text}
							style={{
								width:"100%",
							}}
							multiline={true}
							onChange={(event)=>{
								items.Heroic_attributes[i].text = event.target.value;
								setItems(items);
								saveAPI(items);						
							}}
						/>
					</Item>
				</Grid>
			);
		}
		return grids;
	}

	return (heroic_gridify());
}

