import { character } from "../App";

export default function saveAPI(items){
	window.sessionStorage.setItem('cData',JSON.stringify(items));
	var formData = new FormData();
	formData.append('body', JSON.stringify(items));
	formData.append('cid', window.sessionStorage.getItem('cid'));
	
	fetch("setJson.php", {
		method:'POST',
		head:{
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body:formData,
	}).then(res => res.json()).then((result) => {
		console.log(result);
	});
}

export function saveUser(settings){
	window.sessionStorage.setItem('user_settings',JSON.stringify(settings));
	var formData = new FormData();
	formData.append('body', JSON.stringify(settings));
	formData.append('user_id', window.sessionStorage.getItem('user_id'));
	
	fetch("setUserSettings.php", {
		method:'POST',
		head:{
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body:formData,
	}).then(res => res.json()).then((result) => {
		console.log(result);
	});
}