import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
	DataGrid,
} from '@mui/x-data-grid';
import { Item } from '../Item';
import saveAPI from '../../saveAPI';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Weapon_Grid_Headers } from '../headers';

function createEmptyWeapon(uid) {
	return {
		"id":uid,
		"weapon":[
			{
				"id":"0",
				"col_name1": "Weapon",
				"col_data1":"",
				"col_name2": "Weapon Type",
				"col_data2":""
			},
			{
				"id":"1",
				"col_name1": "Dmg",
				"col_data1":"",
				"col_name2": "Damage Type",
				"col_data2":""
			},
			{
				"id":"2",
				"col_name1": "Grip",
				"col_data1":"",
				"col_name2": "STR-Div",
				"col_data2":""
			},
			{
				"id":"3",
				"col_name1": "BV",
				"col_data1":"",
				"col_name2": "ABS",
				"col_data2":""
			},
			{
				"id":"4",
				"col_name1": "Reload",
				"col_data1":"",
				"col_name2": "Range",
				"col_data2":""	
			},
			{
				"id":"5",
				"col_name1": "Weight",
				"col_data1":"",
				"col_name2": "",
				"col_data2":""
			},
		],
		"description":""
	}
}

export default function Weapons (props) {
	const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')))

	const getUid = () => {
		let index = items.Weapon_Grid_Data.length;
		for(let i = 0; i < items.Weapon_Grid_Data.length; i++){
			if(items.Weapon_Grid_Data[i].id != i){
				index = i;
				break;
			}
		}
		return index;
	}

	const addNewWeapon = () => {
		let tmpItems = JSON.parse(JSON.stringify(items));
		let tempRows = [...tmpItems.Weapon_Grid_Data, createEmptyWeapon(getUid())];
		tempRows.sort(function(a,b){return a.id - b.id});
		tmpItems.Weapon_Grid_Data = tempRows;
		setItems(tmpItems);
		saveAPI(tmpItems);
	}

	const deleteWeapon = (props) => {
		let tmpItems = JSON.parse(JSON.stringify(items));
		let tempWeapons = tmpItems['Weapon_Grid_Data'];
		let weaponProp = -1;
		for(let i=0; i < tempWeapons.length; i++){
			if(props === tempWeapons[i]['id']){
				weaponProp = i;
				break;
			}
		}
		tempWeapons.splice(weaponProp,1);
		tmpItems['Weapon_Grid_Data'] = tempWeapons;
		saveAPI(tmpItems);
		setItems(tmpItems);
	}

	const AddWeaponToolBar = () => {
		return(
			<Stack
				sx={{width:'100%', mb:1}}
				direction='row'
				alignItems='flex-start'
				columnGap={1}
			>
				<Tooltip title={"Add New Weapon"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={addNewWeapon}><AddCircleOutlineIcon /></IconButton></Tooltip>
			</Stack>
		);
	}
	const DeleteWeaponToolBar = (props) => {
		return(
			<Stack
				sx={{width:'100%', mb:1}}
				direction='row'
				alignItems='flex-start'
				columnGap={1}
			>
				<Tooltip title={"Delete Weapon"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px", margin:"5px 0px 0px auto"}} size='small' onClick={() => {deleteWeapon(props.weapon)}}><DeleteIcon /></IconButton></Tooltip>
			</Stack>
		);
	}

	const Description = (props) => {
		return(
			<TextField
				label={"Description"}
				id={"description"}
				InputLabelProps={{ shrink: true }} 
				defaultValue={props.item.weapon.col_description}
				style={{ 
					width: "100%",
				}}
				minRows={3}
				maxRows={10}
				multiline={true}
				onBlur={(event:( object) => void) => {
					items.Weapon_Grid_Data[props.item.weapon.id]['col_description'] = event.target.value;
					setItems(items);
					saveAPI(items);
				}}
			/>
		);
	}

	const Weapon = (props) => {
		return(
			<Grid item xs={12} md={4}>
				<Item>
				<DataGrid id = {"Weapon" + props.weapon.weapon.id}
					rows={props.weapon.weapon}
					columns={Weapon_Grid_Headers} 
					autoHeight={true} 
					density={"compact"}
					pageSize={16}
					hideFooter={true}
					headerHeight={0}
					disableColumnMenu
					initialState={{
						...items.initialState,
						columns:{
						columnVisibilityModel:{
							col_sheet: false
						}},
					}}
					onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
						items.Weapon_Grid_Data[props.weapon.id].weapon[params.id][params.field] = params.props.value;
						saveAPI(items);
					}}
					/>
					<Description item={props} />
					<DeleteWeaponToolBar weapon={props.weapon.id}/>
				</Item>
			</Grid>
		);
	}

	const Arms = () => {
		let w = [];
		items.Weapon_Grid_Data.forEach(function(value, index) {
			w.push(<Weapon weapon={value} />);
		});
		return w;
	}

	return (
		<Box sx={{ 
			flexGrow: 1,
			'& .super-app-theme--cell': {
			  fontWeight: '600',
			}
			}}>
			<Grid container spacing={1}>
				<AddWeaponToolBar />
				<Arms />
			</Grid>
		</Box>
	);
}
