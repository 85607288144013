import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Item } from './Item';
import saveAPI, { saveUser } from '../saveAPI';
import { IconButton, Pagination, PaginationItem, TextField } from '@mui/material';
import { 
	DataGrid,
	GridToolbarContainer,
	GridFilterPanel,
	useGridApiContext,
	useGridSelector,
	gridPageCountSelector,
} from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddTableRow from '../../CustomIcons/AddTableRow';
import RemoveTableRow from '../../CustomIcons/RemoveTableRow';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { skill_headers } from './headers';

function createEmptySkill(uid) {
	return { id: uid, 
		col_skill: "",
		col_ma: "",
		col_SL: "",
		col_exp: "",
		col_notes: "",
		col_description: ""
	}
}
function createEmptySkillExtention(uid, skillID) {
	return { id: uid, 
		col_skill_extention: "",
		col_SL: "",
		col_description: "",
		skill_id: [
			skillID
		]
	}
}

export default function Skills(props) {
	const [items,setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
	const [chosen_skill,setChosen_skill] = useState( null );
	const [chosen_skill_extention,setChosen_skill_extention] = useState( null );
	const [description,setDescription] = useState( "" );
	const [SkillExtentionData,setSkillExtentionData] = useState( [] );
	const [anchorEl,setAnchorEl] = useState( null );
	const [search,setSearch] = useState( false );
	const [searchExt,setSearchExt] = useState( false );
	const loadRowSize = () => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		if(settings['<SkillRowSize>']){
			return settings['SkillRowSize'];
		}
		return settings['SkillRowSize'];
	}

	const [rowSize, setRowSize] = useState(loadRowSize);

	const loadRowSizeExt = () => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		if(settings['SkillExtRowSize']){
			return settings['SkillExtRowSize'];
		}
		return settings['SkillExtRowSize'];
	}

	const [rowSizeExt, setRowSizeExt] = useState(loadRowSizeExt);


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSearch = () => {
		setSearch(!search);
	}
	const handleSearchExt = () => {
		setSearchExt(!searchExt);
	}
		
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const getUid = () => {
		let index = items.Skills_Grid_Data.length;
		for(let i = 0; i < items.Skills_Grid_Data.length; i++){
			if(items.Skills_Grid_Data[i].id !== i){
				index = i;
				break;
			}
		}
		return index;
	}
	const getExUid = () => {
		let index = items.Skill_extentions_Grid_Data.length;
		for(let i = 0; i < items.Skill_extentions_Grid_Data.length; i++){
			if(items.Skill_extentions_Grid_Data[i].id !== i){
				index = i;
				break;
			}
		}
		return index;
	}

	const addNewSkill = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempRows = [...tempItem.Skills_Grid_Data, createEmptySkill(getUid())];
		tempRows.sort(function(a,b){return a.id - b.id});
		tempItem.Skills_Grid_Data = tempRows;
		saveAPI(tempItem);
		setItems(tempItem);
	}
	const addNewSkillExtention = () => {
		let tempRows = [...items.Skill_extentions_Grid_Data, createEmptySkillExtention(getExUid(), chosen_skill)];
		tempRows.sort(function(a,b){return a.id - b.id});
		items.Skill_extentions_Grid_Data = tempRows;
		setItems(items);
		saveAPI(items);
		setSkillExtentionData(SkillExtention(items.Skill_extentions_Grid_Data, chosen_skill));
	}
	const deleteSkill = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let tempSkills = tempItem['Skills_Grid_Data'];
		let skillProp = -1;
		for(let i=0; i < tempSkills.length; i++){
			if(chosen_skill === tempSkills[i]['id']){
				skillProp = i;
				break;
			}
		}
		tempSkills.splice(skillProp,1);
		tempItem['Skills_Grid_Data'] = tempSkills;
		saveAPI(tempItem);
		setItems(tempItem);
		setChosen_skill(null);
	}
	const deleteSkillExtention = () => {
		let tempItem = JSON.parse(JSON.stringify(items));
		let SkillExtentions = tempItem['Skill_extentions_Grid_Data'];
		let skillProp = -1;
		for(let i=0; i < SkillExtentions.length; i++){
			if(chosen_skill_extention === SkillExtentions[i]['id']){
				skillProp = i;
				break;
			}
		}
		SkillExtentions.splice(skillProp,1);
		tempItem['Skill_extentions_Grid_Data'] = SkillExtentions;
		saveAPI(tempItem);
		setItems(tempItem);
		setChosen_skill_extention(null);
		setSkillExtentionData(SkillExtention(tempItem.Skill_extentions_Grid_Data, chosen_skill));
	}

	const SkillToolBar =() => {
		return(
			<GridToolbarContainer >
			<Stack
				sx={{
					display:"flex",
					justifyContent:'start',
					width:'100%', 
					mb:1
				}}
				direction='row'
				alignItems='flex-start'
				columnGap={1}
			>
				<Tooltip title={"New Skill"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={addNewSkill}><AddCircleOutlineIcon /></IconButton></Tooltip>
				<Tooltip title={"Delete Skill"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={deleteSkill}><DeleteIcon /></IconButton></Tooltip>
				<Box sx={{
					marginLeft:"auto",
					display:"flex",
					position:'absolute', 
					right:'4px',
					zIndex:'10',
				}}>
					{search && <GridFilterPanel />}
					<Tooltip title={"Search Skill"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px", maxHeight:"32px"}} size='small' onClick={handleSearch}><SearchSharpIcon /></IconButton></Tooltip>
				</Box>
			</Stack>
		</GridToolbarContainer>
		)
	}
	const SkillExtentionsToolBar = () => {
		return(
			<GridToolbarContainer>
				<Stack
					sx={{
						display:"flex",
						justifyContent:'start',
						width:'100%', 
						mb:1	
					}}
					direction='row'
					alignItems='flex-start'
					columnGap={1}
				>
					<Tooltip title={"New Skill-Extention"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={addNewSkillExtention}><AddCircleOutlineIcon /></IconButton></Tooltip>
					<Tooltip title={"Delete Skill-Extention"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px"}} size='small' onClick={deleteSkillExtention}><DeleteIcon /></IconButton></Tooltip>
					<Box sx={{
						marginLeft:"auto",
						display:"flex",
						position:'absolute', 
						right:'4px',
						zIndex:'10',
					}}>
						{searchExt && <GridFilterPanel />}
						<Tooltip title={"Search Skill-Extention"}><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", padding:"3px", maxHeight:"32px"}} size='small' onClick={handleSearchExt}><SearchSharpIcon /></IconButton></Tooltip>
					</Box>
				</Stack>
			</GridToolbarContainer>
		)
	}
	const handleRowSize = (val) => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		let tmpSize = rowSize+val;
		settings.SkillRowSize = tmpSize;
		setRowSize(tmpSize);
		window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
		saveUser(settings);
	}
	const handleRowSizeExt = (val) => {
		let settings = JSON.parse(window.sessionStorage.getItem('user_settings'));
		let tmpSize = rowSizeExt+val;
		settings.SkillExtRowSize = tmpSize;
		setRowSizeExt(tmpSize);
		window.sessionStorage.setItem('user_settings', JSON.stringify(settings));
		saveUser(settings);
	}

	const CustomPagination = () => {
		const apiRef = useGridApiContext();
		const pageCount = useGridSelector(apiRef, gridPageCountSelector);

		return (
			<Box
				sx={{
					display:"flex",
					width:"100%",
				}}
			>
				<Box
					sx={{
						marginLeft:"8px"
					}}
				>
					Showing {rowSize} of {items.Skills_Grid_Data.length} Skills
				</Box>
				<Box
					sx={{
						marginLeft:"auto",
						display:"flex",
					}}
				>
					<Tooltip title="+1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", padding:"3px"}} size='small' onClick={()=>{handleRowSize(1)}}><AddTableRow/></IconButton></Tooltip>
					<Tooltip title="-1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", marginRight:"3px", padding:"3px"}} size='small' onClick={()=>{handleRowSize(-1)}}><RemoveTableRow/></IconButton></Tooltip>
					<Pagination
						color="primary"
						variant="outlined"
						shape="circular"
						showFirstButton
						showLastButton
						hideNextButton
						hidePrevButton
						count={pageCount}
						renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
						onChange={(event, value) => apiRef.current.setPage(value - 1)}
					/>
				</Box>
			</Box>
		);
	}
	const CustomSkillExtPagination = () => {
		const apiRef = useGridApiContext();
		const pageCount = useGridSelector(apiRef, gridPageCountSelector);

		return (
			<Box
				sx={{
					display:"flex",
					width:"100%",
				}}
			>
				<Box
					sx={{
						marginLeft:"8px"
					}}
				>
					Showing {rowSizeExt} of {SkillExtentionData.length} Skill-Extentions
				</Box>
				<Box
					sx={{
						marginLeft:"auto",
						display:"flex",
					}}
				>
					<Tooltip title="+1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", padding:"3px"}} size='small' onClick={()=>{handleRowSizeExt(1)}}><AddTableRow/></IconButton></Tooltip>
					<Tooltip title="-1 row/page"><IconButton color='primary' sx={{border:"1px solid rgba(0, 0, 0, 0.23)", marginLeft:"6px", marginRight:"3px", padding:"3px"}} size='small' onClick={()=>{handleRowSizeExt(-1)}}><RemoveTableRow/></IconButton></Tooltip>
					<Pagination
						color="primary"
						variant="outlined"
						shape="circular"
						showFirstButton
						showLastButton
						hideNextButton
						hidePrevButton
						count={pageCount}
						renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
						onChange={(event, value) => apiRef.current.setPage(value - 1)}
					/>
				</Box>
			</Box>
		);
	}
					

	const Description = () => {
		return(
			<TextField
				label={"Description"}
				id={"description"}
				InputLabelProps={{ shrink: true }} 
				defaultValue={description}
				style={{ 
					width: "100%",
				}}
				minRows={7}
				maxRows={20}
				multiline={true}
				onBlur={(event:( object) => void) => {
					setDescription(event.target.value);
					if(chosen_skill_extention === null){
						items.Skills_Grid_Data[chosen_skill]['col_description'] = event.target.value;
					}else{
						items.Skill_extentions_Grid_Data[chosen_skill_extention]['col_description'] = event.target.value;
					}
					setItems(items);
					saveAPI(items);
				}}
			/>
		)
	}

	function SkillExtention(data, cskill) {
		let skill_extentions = [];
		for (let i = 0; i < data.length; i++){
				if(data[i].skill_id.includes(cskill)){
						skill_extentions.push(data[i]);
				}
		}
		return skill_extentions;
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={7}>
					<Item>
						<DataGrid id = {"Skills"}
							rows={items.Skills_Grid_Data} 
							columns={skill_headers}
							autoHeight={true} 
							density={"compact"} 
							pageSize={rowSize}
							rowsPerPageOptions={rowSize}
							hideFooterSelectedRowCount
							columnVisibilityModel={{
								col_sheet: false
							}}
							initialState={{
								...items.initialState,
							}}
							components={{
								Toolbar:SkillToolBar,
								Pagination:CustomPagination
							}}
							onRowClick = { (params: GridRowParams) => {
								setDescription(params.row['col_description']);
								setChosen_skill(params.id);
								setChosen_skill_extention(null);
								setSkillExtentionData(SkillExtention(items.Skill_extentions_Grid_Data, params.id))
							}}
							onEditCellPropsChange={(params: GridEditCellPropsParams, event: MuiEvent<React.SyntheticEvent>) => {
								try {
									let tempItem = JSON.parse(JSON.stringify(items));
									let Skills = tempItem['Skills_Grid_Data'];
									let skillProp = -1;
									for(let i=0; i < Skills.length; i++){
										if(params.id === Skills[i]['id']){
											skillProp = i;
											break;
										}
									}

									items.Skills_Grid_Data[skillProp][params.field] = event.target.value;
									setItems(items);
									saveAPI(items);
								} catch (error) {
									console.log(error);
								}
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={12} md={5}>
					<Item>
						<Description />
					</Item>
					<Item id="skill_extention_item" sx={{ "marginTop":"8px"}}>
						<DataGrid id = {"SkillExtentions"}
							rows={SkillExtentionData}
							columns={items.Skill_extentions_Grid_Headers} 
							autoHeight={true} 
							density={"compact"}
							pageSize={rowSizeExt}
							rowsPerPageOptions={rowSizeExt}
							components={{
								Toolbar:SkillExtentionsToolBar,
								Pagination:CustomSkillExtPagination
							}}
							onRowClick = { (params: GridRowParams) => {
								setDescription(params.row['col_description']);
								setChosen_skill_extention(params.id);
							}}
							onEditCellPropsChange={(params: GridEditCellProps.itemParams, event: MuiEvent<React.SyntheticEvent>) => {
								try {
									let tempItem = JSON.parse(JSON.stringify(items));
									let SkillExtentions = tempItem['Skill_extentions_Grid_Data'];
									let skillProp = -1;
									for(let i=0; i < SkillExtentions.length; i++){
										if(params.id === SkillExtentions[i]['id']){
											skillProp = i;
											break;
										}
									}
						
									items.Skill_extentions_Grid_Data[skillProp][params.field] = event.target.value;
									setItems(items);
									saveAPI(items);
								} catch (error) {
									console.log(error);
								}
							}}
						/>
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
}