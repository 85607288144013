import { TextField } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../App";
import saveAPI from "../saveAPI";
import { Item } from "./Item";

export default function Description (props) {
    const [desId, setDesId] = useContext(UserContext);
    const [items, setItems] = useState(JSON.parse(window.sessionStorage.getItem('cData')));
    const [description, setDescription] = useState("");
    if(description !== items[props.data][desId]['col_description']){
        setDescription(items[props.data][desId]['col_description'])
    }

    return(
        <Item>
            <TextField
                label={"Description"}
                id={"description"}
                InputLabelProps={{ shrink: true }} 
                value={description}
                style={{
                    width: "100%",
                }}
                minRows={3}
                maxRows={20}
                multiline={true}
                onChange={(event) => {
                    setDescription(event.target.value)
                    items[props.data][desId].col_description = event.target.value;
                    setItems(items);
                    saveAPI(items);
                }}
            />
        </Item>
    )
}